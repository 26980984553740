import { EIndemniteType } from "./EIndemniteType";
import { IIndemnite } from "./IIndemnite";
import { IndemniteError } from "./IndemniteError";

export class Indemnite implements IIndemnite {
	//#region PROPERTIES

	/** @implements */
	public id: string;
	/** @implements */
	public price: number;
	/** @implements */
	public  type: EIndemniteType;
	/** @implements */
	public  label?: string;
	public  distance?: number;
	/** @implements */
	public readonly description: string;
	/** @implements */
	public disabled?: boolean;
	/** @implements */
	public force?: boolean;

	/** Indique si l'indemnité est une IK ou non. */
	public get isIKType(): boolean {
		return (
			this.type === EIndemniteType.IKM ||
			this.type === EIndemniteType.IK ||
			this.type === EIndemniteType.IKS
		);
	}

	//#endregion

	//#region METHODS

	constructor(
		psId: string,
		peType: EIndemniteType,
		psDescription: string,
		peLabel?:string,
		pnPrice: number = NaN,
		pbDisabled?: boolean
	) {
		this.id = psId;
		this.type = peType;
		this.label=peLabel;
		this.description = psDescription;
		this.price = pnPrice;
		this.disabled = pbDisabled;
	}

	/** Crée une instance d'indemnités à partir des données d'un objet similaire récupérées en base de données.
	 * @param poData Données de l'objet récupérées en bases de données dont il faut créer une instance de `Indemnites`.
	 * @throws `IndemniteError` si la donnée à instancier n'est pas valide.
	 */
	public static getInstanceFromData(poData: IIndemnite): Indemnite {
		if (poData) {
			const loIndemnite = new Indemnite(
				poData.id,
				poData.type,
				poData.description,
				poData.label,
				poData.price
			);
			Object.defineProperty(loIndemnite, "disabled", {
				value: poData.disabled,
			});
			loIndemnite.distance = poData.distance;
			loIndemnite.force = poData.force;
			return loIndemnite;
		} else
			throw new IndemniteError(
				"Impossible d'instancier une indemnité sans paramètre !"
			);
	}

	//#endregion
}

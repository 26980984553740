import { Component, Input, OnInit, OnChanges, SimpleChanges } from '@angular/core';
import { StoredSeance } from 'apps/idl/src/anakin/models/StoredSeance';
import { EStatusSeance } from 'apps/idl/src/model/EStatusSeance';
import { Traitement } from 'apps/idl/src/model/Traitement';
import { IPatient } from 'apps/idl/src/modules/patients/model/IPatient';
import { PatientsService } from 'apps/idl/src/modules/patients/services/patients.service';
import { TraitementService } from 'apps/idl/src/services/traitement.service';
import { concatMap, map } from 'rxjs/operators';
import { DrawerPopoverService } from '../../../shared/services/drawer-popover.service';
import { MenuGroupeJourneeComponent } from '../menu-groupe-journee/menu-groupe-journee.component';
import { EMoments } from '../../../shared/enums/EMoments';
import { Recurrence } from '@osapp/modules/event-markers/models/recurrence';
import { EEtatActe } from '../../../shared/enums/EEtatActe';
import { Evenement } from 'apps/idl/src/anakin/models/Evenement';

@Component({
  selector: 'di-liste-seance-tournees',
  templateUrl: './liste-seance-tournees.component.html',
  styleUrls: ['./liste-seance-tournees.component.scss'],
})
export class ListeSeanceTourneesComponent implements OnInit, OnChanges {
  @Input() date: Date = new Date();
  @Input() seances: StoredSeance[] = [];
   @Input() events : Evenement[]=[];
  public traitementIdsTab: string[];
  public ordonnances: Map<string, Traitement> = new Map();
  public sortedSeances: { [key: string]: { type: 'seance' | 'event', data: StoredSeance | Evenement }[] } = {};

  public nbSoinRealise: number = 0;
  public totalMtRealises: number = 0;
  public labelSoinRealise: string = "Aucun soin réalisé";
  public patients: Map<string, IPatient>;
  public patientIdsTab: string[];

  public moments = [
    { key: EMoments.MATIN, label: EMoments.MATIN, labelNoData: "Aucune séance n'est prévue le matin"},
    { key: EMoments.APRES_MIDI, label: EMoments.APRES_MIDI, labelNoData: "Aucune séance n'est prévue l'après-midi"},
    { key: EMoments.SOIR, label: EMoments.SOIR, labelNoData: "Aucune séance n'est prévue le soir"},
    { key: EMoments.NUIT, label: EMoments.NUIT, labelNoData: "Aucune séance n'est prévue la nuit"},
  ];
  public momentSeances: StoredSeance[];

  constructor(
    private svcTraitement: TraitementService,
    private svcPatient: PatientsService,
    private svcDrawerPopover: DrawerPopoverService
  ) { }

  ngOnInit() {
   
    // Initialisation au démarrage du composant
    this.sortSeancesByTimeOfDay();
    this.getSoinRealise();
  }

  ngOnChanges(changes: SimpleChanges) {
    // Appelé chaque fois qu'une des propriétés @Input change
    if (changes['seances']) {
      this.getTraitementIdsAndPatientId();

      this.svcTraitement.getTraitementByIds(this.traitementIdsTab)
        .pipe(
          map(ordonnances => this.createOrdonnanceMap(ordonnances)),
          concatMap(() =>
            this.svcPatient.getPatientsByIds(this.patientIdsTab)
              .pipe(
                map(patients => this.createPatientMap(patients))
              )
          )
        )
        .subscribe(() => {
          this.sortSeancesByTimeOfDay();
          this.getSoinRealise();
        });
    }
  }

  private createOrdonnanceMap(ordonnances: Traitement[]) {
    const ordonnanceMap = new Map<string, Traitement>();
    ordonnances.forEach(ordonnance => {
      ordonnanceMap.set(ordonnance._id, ordonnance); // assuming ordonnance has an id property
    });
    this.ordonnances = ordonnanceMap;
  }

  private createPatientMap(patients: IPatient[]) {
    const patientMap = new Map<string, IPatient>();
    patients.forEach(pat => {
      patientMap.set(pat._id, pat); // assuming ordonnance has an id property
    });
    this.patients = patientMap;
  }

  private getTraitementIdsAndPatientId() {
    const traitementIds = new Set<string>();
    const patientIds = new Set<string>();

    this.seances.forEach(seance => {
      if (seance.traitementId) {
        traitementIds.add(seance.traitementId);
      }

      if (seance.patientId) {
        patientIds.add(seance.patientId);
      }
    });

    if (patientIds.size > 0) {
      this.patientIdsTab = Array.from(patientIds);
    }

    if (traitementIds.size > 0) {
      this.traitementIdsTab = Array.from(traitementIds);
    }
    return Array.from(traitementIds);
  }

  private sortSeancesByTimeOfDay() {
    this.sortedSeances = {}; // Reset the sorted data
  
    // Sort Seances
    this.seances.forEach((seance: StoredSeance) => {
      const moment = StoredSeance.determineMoment(new Date(seance.startDate));
      if (!this.sortedSeances[moment]) {
        this.sortedSeances[moment] = [];
      }
      this.sortedSeances[moment].push({ type: 'seance', data: seance });
    });
  
    // Sort Events
    this.events.forEach((event: Evenement) => {
      let moment;
    
      // Check if the moment type is 'hours-minutes'
      if (event.moment?.type === 'hours-minutes') {
       
        const hour = event.moment?.hours ;
    
        // Determine the moment based on the hour
        switch (true) {
          case (hour >= 6 && hour < 12):
            moment = EMoments.MATIN;
            break;
          case (hour >= 12 && hour < 18):
            moment = EMoments.APRES_MIDI;
            break;
          case (hour >= 18 && hour < 23):
            moment = EMoments.SOIR;
            break;
          case (hour < 6 || hour >= 23):
            moment = EMoments.NUIT;
            break;
          default:
            throw new Error("Invalid time range for moment");
        }
      } else {
        // Assuming the moment determination logic is the same for other cases
        moment = StoredSeance.determineMoment(event.moment);
      }
    
      // Push the event data into the sortedSeances array
      if (!this.sortedSeances[moment]) {
        this.sortedSeances[moment] = [];
      }
      this.sortedSeances[moment].push({ type: 'event', data: event });
    });
  

  }
  
  private getSoinRealise() {
    let seancesRealisees: StoredSeance[] = this.seances.filter(x => x.status === EStatusSeance.done);
    this.nbSoinRealise = seancesRealisees?.length;
    this.getMontantSoinRealise(seancesRealisees);
    this.getLabelSoinRealise();
  }

  

  private getMontantSoinRealise(seancesRealisees: StoredSeance[]) {
    this.totalMtRealises = seancesRealisees.reduce((total, seance) => {
      let sommeActes = seance.actes?.reduce((total, acte) => {
        // Seuls les actes réalisés sont pris en compte pour le montant
        if(acte.etat === EEtatActe.done)
          return total + acte.price
        else
        return total
      }, 0) || 0;
      let sommeMajorations = seance.majorations?.reduce((total, majoration) => total + majoration.price, 0) || 0;
      let sommeIndemnites = seance.indemnites?.reduce((total, indemnite) => total + indemnite.price, 0) || 0;

      return total + sommeActes + sommeMajorations + sommeIndemnites;
    }, 0);
  }

  private getLabelSoinRealise() {
    if (this.nbSoinRealise === 0) {
      this.labelSoinRealise = "Aucun soin realisé";
    } else if (this.nbSoinRealise === 1) {
      this.labelSoinRealise = "1 soin realisé";
    } else {
      this.labelSoinRealise = `${this.nbSoinRealise} soins realisés`;
    }
  }

  getSeancesByMoment(moment: EMoments) {
    const repetition = Recurrence.getRepetition(moment);
    this.momentSeances = this.seances.filter((seance) => {
      const hour = new Date(seance.startDate).getHours();
      if (repetition.from.hours < repetition.to.hours) {
        return hour >= repetition.from.hours && hour < repetition.to.hours;
      } else {
        return hour >= repetition.from.hours || hour < repetition.to.hours;
      }
    });
  }


  handleClickMomentMenu(event: MouseEvent, momentLabel: string) {
    const momentEnum = Object.values(EMoments).find(value => value === momentLabel);
    this.getSeancesByMoment(momentEnum);
    this.svcDrawerPopover.open(
      momentEnum,
      "30%",
      event.currentTarget,
      MenuGroupeJourneeComponent,
      {
        momentLabel: momentEnum,
        seances: this.momentSeances,
      }
    );
  }


}
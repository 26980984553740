<div class="di-liste-seance-tournees__container">
	<div class="liste-seances-tournees">
    <div class="seance-tournees">
      <div *ngFor="let moment of moments" class="seance-moment">
        <div class="separateur-group">
          <lua-separateur-groupe [letter]="false" [label]="moment.label"></lua-separateur-groupe>
          <lua-bouton-icone iconName="more_vert" (click)="handleClickMomentMenu($event, moment.label)"></lua-bouton-icone>
        </div>
        <lua-message-aucune-donnee *ngIf="!sortedSeances[moment.key]"
				mainText="Aucun soin"
        [subText]="moment.labelNoData"
        buttonLabel=""
        iconName=""
			></lua-message-aucune-donnee>
        <div *ngFor="let item of sortedSeances[moment.key]">
          <ng-container *ngIf="item.type === 'seance'">
            <di-carte-seance
              [seance]="item.data"
              [patient]="patients.get(item.data.patientId)"
              [ordonnance]="ordonnances.get(item.data.traitementId)">
            </di-carte-seance>
          </ng-container>
          <ng-container *ngIf="item.type === 'event'">
            <di-carte-evenement
              [evenement]="item.data">
            </di-carte-evenement>
          </ng-container>
        </div>
      </div>
      <lua-divider-horizontal size="medium"></lua-divider-horizontal>
      <div class="seance-realisee">
        <strong>{{labelSoinRealise}}</strong>
        <p>{{totalMtRealises | currency: 'EUR' : 'symbol' : '1.2-2' : 'fr-FR'}}</p>
      </div>
    </div>
	</div>
</div>

import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DateHelper } from '@osapp/helpers';
import { ETimetablePattern } from '@osapp/model';
import { DestroyableComponentBase } from '@osapp/modules/utils/components/destroyable-component-base';
import { Observable, forkJoin, of } from 'rxjs';
import { mergeMap, switchMap, takeUntil, tap } from 'rxjs/operators';
import { ITraitement } from '../../../../model/ITraitement';
import { Traitement } from '../../../../model/Traitement';
import { IPatient } from '../../../../modules/patients/model/IPatient';
import { PatientsService } from '../../../../modules/patients/services/patients.service';
import { TraitementService } from '../../../../services/traitement.service';
import { NB_ITEM_TO_DISPLAY } from '../../../anakin.constants';
import { PanneauPatientOrdonanceComponent } from '../../../features/ordonnances/components/panneau-patient-ordonnance/panneau-patient-ordonnance.component';
import { DeviceService } from '../../../features/shared/services/device.service';
import { PanneauService } from '../../../features/shared/services/panneau.service';
import { SeanceService } from '../../../features/shared/services/seance.service';
import { EStatusSeance } from 'apps/idl/src/model/EStatusSeance';
import { LoaderService } from '../../../features/shared/services/loader.service';

@Component({
  selector: 'di-ordonnance',
  templateUrl: './ordonnance.page.html',
  styleUrls: ['./ordonnance.page.scss'],
})
export class OrdonnancePage extends DestroyableComponentBase implements OnInit {
  public ordonnancefilters: Array<string> = ["ACTIVES", "TERMINÉES"];
  public defaultFilter= this.ordonnancefilters[0];
	public ordoActiveCountMessage :string;
	public isMobileView: boolean = false;
	public searchedValue : string = "";
	public ordonnances: ITraitement[] = [];
  public filteredOrdonnance: ITraitement[] = [];
	public aucuneOrdonnance : boolean = false;
	public MainTextNoResult : string;
	public SubTextNoResult : string;
	private patientIds :string[];
  public patientsMap: Map<string, IPatient> = new Map();
  public displayCount: number = NB_ITEM_TO_DISPLAY;
 
  public countSeancesByTraitement: Map<string, number> = new Map();

  constructor( 
		private svcDevice : DeviceService,
		private svcPatient : PatientsService,
		private svcPanneau: PanneauService,
		private svcTraitement : TraitementService,	
		private router: Router,
    private svcSeance: SeanceService,
    private svcLoader: LoaderService
		) {
			super()
		 }

  ngOnInit() {
		this.svcDevice.isMobile$.pipe(takeUntil(this.destroyed$)).subscribe((flag: boolean) => {
      this.isMobileView = flag;
    });
		this.svcTraitement.deletion$.pipe(takeUntil(this.destroyed$)).subscribe(() => {
      this.loadListOrdo();
    });
		this.svcPanneau.closePanel$.pipe(takeUntil(this.destroyed$)).subscribe(() => {
      this.loadListOrdo();
    });
		this.loadListOrdo();

	}

	private loadListOrdo() : void {
    this.svcLoader.showLoader();
		this.initOrdonnance().pipe(
      switchMap(() => {
        // On récupère le nombre de séances 'to be done' de chaque traitement
        const countSeancesObservables = this.ordonnances.map((ordonnance: ITraitement) =>
          this.svcSeance.countSeancesByStatus(ordonnance, EStatusSeance.to_be_done).pipe(
            mergeMap((count: number) => {
              this.countSeancesByTraitement.set(ordonnance._id, count);
              
              // Si on n'a aucune séance to be done, on compte le nombre de séances total de l'ordonnance
              //pour voir les traitements qui n'ont pas de séances
              if(count === 0){
                return this.svcSeance.countSeancesByTraitement(ordonnance)
              }
              return of(count)
            }),
            tap((count: number) => {
              // Si le nombre total de séance est 0, alors on met 1 pour que l'ordo soit considérée active
              if(count === 0) this.countSeancesByTraitement.set(ordonnance._id, 1);
            })
          )
        );
        return forkJoin(countSeancesObservables);
      }),
			tap(() => {
				this.applyFilter();
				this.getNbOrdonnance();
				this.aucuneOrdonnance = this.ordonnances.length === 0;
				if(this.aucuneOrdonnance)
				{
					this.MainTextNoResult = "Aucune ordonnance";
					this.SubTextNoResult = "Vous allez retrouver ici toutes les ordonnances par patient.";
				}
			}),
			switchMap(() => {
				this.patientIds = Array.from(new Set(this.ordonnances.map(ordo => {
					return Traitement.extractPatientId(ordo._id);
				}).filter(id => id)));
				return forkJoin({
					patients: this.svcPatient.getPatientsByIds(this.patientIds)
				});
			}),
			tap(({patients}) => {
				this.patientsMap = new Map(patients.map(patient => [patient._id, patient]));
			}),
      tap((_) => {
        this.svcLoader.hideLoader();      
      }),
			takeUntil(this.destroyed$)
		).subscribe();
	}

	private initOrdonnance(): Observable<ITraitement[]> {
    //TODO : Si demain on a beaucoup de traitement à récupérer il faudra utiliser les options "skip" et "limit" 
    //car actuellement on récupère tous les traitements
    return this.svcTraitement.getTraitementsANAKIN(false).pipe(
        tap((traitements: ITraitement[]) => {
            if (traitements.length === 0) {
                this.ordonnances = [];
            } else {
                this.ordonnances = traitements.map(traitement => {
                    return {
                        ...traitement,
                        createdDate: new Date(traitement.createDate)
                    };
                });

                this.ordonnances.sort((a, b) => {
                    return (b.createDate as Date).getTime() - (a.createDate as Date).getTime();
                });
            }
        })
    );
}


	private applyFilter(): void {
		this.filteredOrdonnance = this.ordonnances;
    if (this.defaultFilter === "ACTIVES") {
      // Les ordonnances actives sont celles pour qui il reste au moins une séance non réalisée
      this.filteredOrdonnance = this.filteredOrdonnance.filter(ordonnance => {
        // Si on n'a pas de count de séances dans la map, alors on met 1 pour que l'ordonnance soit considérée active
        return (this.countSeancesByTraitement.get(ordonnance._id) ?? 1) > 0;
      });
    } else if (this.defaultFilter === "TERMINÉES") {
      this.filteredOrdonnance = this.filteredOrdonnance.filter(ordonnance => {
        return (this.countSeancesByTraitement.get(ordonnance._id) ?? 1) === 0;
      });
    }

    this.filteredOrdonnance.sort((a, b) => {
      return (b.createDate as Date).getTime() - (a.createDate as Date).getTime();
    });
  }

  showMore() {
    this.displayCount += NB_ITEM_TO_DISPLAY;
  }

  public addOrdonnance() {

  }

  public filterOrdonnances(searchValue: string) {
		this.searchedValue = searchValue.trim().toLowerCase();
    if (this.searchedValue && this.searchedValue.length > 2) {
			  this.applyFilter();
        const searchTerms = this.searchedValue.split(' ').filter(term => term);
        this.filteredOrdonnance = this.filteredOrdonnance.filter(ordonnance => {
            const patient = this.patientsMap.get(Traitement.extractPatientId(ordonnance._id));
            if (!patient) return false;

            const firstName = patient.firstName ? patient.firstName.toLowerCase() : '';
            const lastName = patient.lastName ? patient.lastName.toLowerCase() : '';
						const formattedBirthDateSlash = DateHelper.transform(patient.birthDate, ETimetablePattern.dd_MM_yyyy_slash);

            const matchesOnlyFirstName = searchTerms.length === 1 &&
                searchTerms[0] && firstName.includes(searchTerms[0]);

            const matchesOnlyLastName = searchTerms.length === 1 &&
                searchTerms[0] && lastName.includes(searchTerms[0]);

            const matchesFirstLast = searchTerms.length === 2 &&
                firstName.includes(searchTerms[0]) && lastName.includes(searchTerms[1]);

            const matchesLastFirst = searchTerms.length === 2 &&
                lastName.includes(searchTerms[0]) && firstName.includes(searchTerms[1]);

					const matchesSocialNumber = patient.socialNumber?.startsWith(this.searchedValue);
					const matchesBirthDate = this.searchedValue === formattedBirthDateSlash;

					return matchesOnlyFirstName || matchesOnlyLastName || matchesFirstLast || matchesLastFirst || matchesSocialNumber || matchesBirthDate;
        });
    } else {
				if(searchValue === ""){
					this.applyFilter();
				}
        this.filteredOrdonnance = this.filteredOrdonnance;
    }
		this.aucuneOrdonnance = this.filteredOrdonnance.length === 0;
		if(this.aucuneOrdonnance)
		{
			this.MainTextNoResult = "Aucune ordonnance trouvée";
			this.SubTextNoResult = "Essayer de modifier votre recherche";
		}
		this.getNbOrdonnance();
  }

  public showOrdonnanceList() {
    return this.filteredOrdonnance.length > 0;
  }

	public getNbOrdonnance() : void {
    const nbOrdoActive = this.filteredOrdonnance.length ;
		if(nbOrdoActive == 0)
		{
			this.ordoActiveCountMessage = "Aucune ordonnance";
		}
		else
		{
    	this.ordoActiveCountMessage = `${nbOrdoActive} ordonnance${nbOrdoActive > 1 ? "s actives" : " active"}`;
		}
  }

	public changeFiltre(filtre: string )
	{
    this.defaultFilter	= filtre;
		this.applyFilter();
		if(this.searchedValue && this.searchedValue.length > 2)
      this.filterOrdonnances(this.searchedValue);
  }

  public handleClickAddOrdonnance(event: Event) {
    this.svcPanneau.open("Patients",PanneauPatientOrdonanceComponent,{ })
  }

	public navigateToOrdo(ordonnanceId :ITraitement)
	{
		this.router.navigate(['/ordonnances/edit', ordonnanceId]);
	}

}
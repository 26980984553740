import { Component, ElementRef, OnInit } from '@angular/core';
import { ArrayHelper, DateHelper } from '@osapp/helpers';
import { EPrefix, IContact, UserData } from '@osapp/model';
import { DestroyableComponentBase } from '@osapp/modules/utils/components/destroyable-component-base';
import { ContactsService } from '@osapp/services';
import { Observable, Subject, forkJoin, merge } from 'rxjs';
import { map, switchMap, takeUntil, tap } from 'rxjs/operators';
import { MenuSelectionInfirmierComponent } from '../../features/shared/components/menu-selection-infirmier/menu-selection-infirmier.component';
import { DeviceService } from '../../features/shared/services/device.service';
import { DrawerPopoverService } from '../../features/shared/services/drawer-popover.service';
import { EvenementService } from '../../features/shared/services/evenement.service';
import { LoaderService } from '../../features/shared/services/loader.service';
import { SeanceService } from '../../features/shared/services/seance.service';
import { Evenement } from '../../models/Evenement';
import { StoredSeance } from '../../models/StoredSeance';

@Component({
  selector: 'di-tournees',
  templateUrl: './tournees.page.html',
  styleUrls: ['./tournees.page.scss'],
})
export class TourneesPage extends DestroyableComponentBase implements OnInit {
  public tourneesfilters: Array<string> = ["Liste", "Agenda"];
  public defaultFilter = this.tourneesfilters[0];
  public isMobile: boolean = false;
  public nbRdvPatient: number = 0;
  public totalPrevu: number = 0;
  public today: Date = new Date();
  public seances: StoredSeance[] = [];
  public events :Evenement[]=[];
  public infirmierSelected: IContact[];

  public infirmiersWS: IContact[];
	public libelleInfirmier :string;

  public dateSelected: Date;
  private dateSelectedSubject = new Subject<Date>();

  constructor(
    private svcDevice: DeviceService,
    private svcSeance: SeanceService,
    private svcDrawerPopover: DrawerPopoverService,
    private svcContact: ContactsService,
    private svcLoader: LoaderService,
    private svcEvenement: EvenementService,
		private el: ElementRef
  ) { super(); }

  ngOnInit() {
    this.initDateSelected();
    this.initInfirmier();
  
    // Listen for mobile changes
    this.svcDevice.isMobile$
      .pipe(takeUntil(this.destroyed$))
      .subscribe((flag: boolean) => {
        this.isMobile = flag;
      });
  
   
      merge(
				this.svcSeance.refreshSeanceList$,
				this.svcEvenement.refreshEventList$
			)
			.pipe(
				takeUntil(this.destroyed$),
				switchMap(() => this.fetchData(this.dateSelected, this.infirmierSelected))
			)
			.subscribe();
		
			this.dateSelectedSubject
				.pipe(
					tap(() => this.svcLoader.showLoader()),
					takeUntil(this.destroyed$),
					switchMap((date: Date) => {
						this.dateSelected = date;
						return this.fetchData(date, this.infirmierSelected);
					}),
					tap(() => this.svcLoader.hideLoader())
				)
				.subscribe();
  }

	private fetchData(date: Date, infirmiers: IContact[]): Observable<[StoredSeance[], Evenement[]]> {
		const infirmierIds : string[]= this.infirmierSelected.map(infirmier => infirmier._id);
		return forkJoin([
			this.fetchSeances(date, infirmierIds),
			this.fetchEvents(date, infirmierIds)
		]);
	}
  

  private fetchSeances(date: Date, infirmierIds: string[]) {
    return this.svcSeance.selectSeancesByDateAndInfirmier(date, infirmierIds).pipe(
      tap((seances: StoredSeance[]) => {
        this.seances = seances;
        this.nbRdvPatient = seances.length;
      })
    );

  }
  private fetchEvents(date: Date, infirmierIds: string[]) {
    return this.svcEvenement.selectEventsByDateAndInfirmier(date, infirmierIds).pipe(
      tap((events: Evenement[]) => {
      this.events=events
      })
    );

  }

  handleClickToday() {
    this.dateSelectedSubject.next(this.today);
  }

  openMenuSelectionInfirmier(event: Event) {
    //On regarde si on a déjà récupérer les autres infirmiers du WS
    this.getInfirmiersWorkspace();

    this.svcDrawerPopover.open("",
      "400px",
      event.currentTarget,
      MenuSelectionInfirmierComponent,
      {
        infirmiers: this.infirmiersWS,
        onSelection: this.selectionNewInfirmier
      })
  }

  selectionNewInfirmier = (infirmiers: IContact[]): void => {
			this.infirmierSelected = infirmiers;
			this.svcDrawerPopover.close();
			this.dateSelectedSubject.next(this.dateSelected);
			if(infirmiers.length == 1){
				this.libelleInfirmier = `${ArrayHelper.getFirstElement(this.infirmierSelected).firstName} ${ArrayHelper.getFirstElement(this.infirmierSelected).lastName}`;
				this.el.nativeElement.style.setProperty(
					'--my-color',
					`var(--${ArrayHelper.getFirstElement(this.infirmierSelected).avatarCouleur ?? 'CouleurPrimaire'})`
				);
			}
			else
			{
				this.libelleInfirmier = 'Tous les infirmiers';
				this.el.nativeElement.style.setProperty(
					'--my-color',
					`var(--CouleurPrimaire)`
				);
			}
  }

  private getInfirmiersWorkspace() {
    if (!this.infirmiersWS || this.infirmiersWS.length === 0) {
      //On récupère les infirmiers en bdd
      this.svcContact.getSiteContactsAnakin([], EPrefix.contact, true, true)
        .pipe(
          map((contacts: IContact[]) => {
            return contacts.filter((contact: IContact) => {
              return contact.userId;
            });
          }),
          tap((contacts: IContact[]) => { this.infirmiersWS = contacts; }),
          takeUntil(this.destroyed$)
        ).subscribe();
    }
  }

  previousDay() {
    const newDate = DateHelper.addDays(this.dateSelected, -1);
    this.dateSelectedSubject.next(newDate);
  }

  nextDay() {
    const newDate = DateHelper.addDays(this.dateSelected, 1);
    this.dateSelectedSubject.next(newDate);
  }

  changeFilter(filtre: string) {
    this.defaultFilter = filtre;
    //il faut changer de vue et recharger toutes les séances d'une semaine
  }

  private initDateSelected() {
    this.dateSelected = this.today;
  }

  private initInfirmier() {
    this.svcContact.getContactFromUserId(UserData.current?.name)
      .pipe(
        takeUntil(this.destroyed$)
      ).subscribe((contact) => {
        this.infirmierSelected = [contact];
        this.dateSelectedSubject.next(this.dateSelected);
				this.libelleInfirmier = `${ArrayHelper.getFirstElement(this.infirmierSelected).firstName} ${ArrayHelper.getFirstElement(this.infirmierSelected).lastName}`;
				this.el.nativeElement.style.setProperty(
					'--my-color',
					`var(--${contact.avatarCouleur ?? 'CouleurPrimaire'})`
				);
      });
  }
}

import { Component, OnDestroy, OnInit } from "@angular/core";
import { Router } from '@angular/router';
import { Subscription } from "rxjs";
import { DeviceService } from "../../features/shared/services/device.service";

@Component({
	selector: "di-parametres",
	templateUrl: "./parametres.page.html",
	styleUrls: ["./parametres.page.scss"],
})
export class ParametresPage implements OnInit, OnDestroy {
	public utilisateursSelected: boolean;
	showUtilisateurs: boolean;
	public isMobileView: boolean;
	private subscriptions: Subscription[] = [];
	public showMenu: boolean;
	public showPageDroite: boolean;

	constructor(private svcDevice: DeviceService, private router : Router) {}

	public ngOnDestroy(): void {
		if (this.subscriptions) {
			this.subscriptions.forEach((sub) => sub.unsubscribe());
			this.subscriptions = [];
		}
	}

	ngOnInit() {
		this.subscriptions.push(
			this.svcDevice.isMobile$.subscribe((flag: boolean) => {
				this.isMobileView = flag;
			})
		);
		this.showMenu = true;
		this.utilisateursSelected = true;
		if (!this.isMobileView) {
			this.showUtilisateurs = true;
			this.showPageDroite = this.utilisateursSelected;
		}
	}

	handleSelectionUtilisateurs() {
		if (this.isMobileView) {
			this.showMenu = false;
			this.showPageDroite = true;
			this.utilisateursSelected = true;
			this.showUtilisateurs = true;
		}else{
			this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
				this.router.navigate(["parametres"])
			});
		}
		
	}
	handleRetourParametres() {
		this.showPageDroite = false;
		this.showMenu = true;
	}
}

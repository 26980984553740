import {
	Component,
	Input,
	OnInit
} from "@angular/core";
import { EPrefix, IContact } from "@osapp/model";
import { DestroyableComponentBase } from "@osapp/modules/utils/components/destroyable-component-base";
import { ContactsService } from "@osapp/services";
import { map } from "rxjs/operators";

@Component({
	selector: "di-menu-selection-infirmier",
	templateUrl: "./menu-selection-infirmier.component.html",
	styleUrls: ["./menu-selection-infirmier.component.scss"],
})
export class MenuSelectionInfirmierComponent
	extends DestroyableComponentBase
	implements OnInit
{
	@Input() onSelection: (contacts: IContact[]) => void;
	infirmiers: IContact[] = [];
	maxAffichage = 3;

	constructor(private svcContacts: ContactsService) {
		super();
	}

	ngOnInit(): void {
		this.svcContacts.getSiteContactsAnakin([], EPrefix.contact, true, true).pipe(
      map((contacts: IContact[]) => {
        return contacts.map((contact: IContact) => {
          if(!contact.isDoctor && contact.finess)
         		contact.isDoctor = true; 
          return contact;
        });
      }),
    ).subscribe((contacts: IContact[]) => {
      this.infirmiers = contacts.filter(contact => contact.userId);
		});
	}

	getAvatarColor(infirmier : IContact) {
		return infirmier.avatarCouleur ?? 'CouleurPrimaire';
  }
	
				

	onSelectionItem(infirmier: IContact)
	{
		if(this.onSelection)
		{
			this.onSelection([infirmier]);
		}
	}

	afficherTous(): void {
		if(this.onSelection)
		{
			this.onSelection(this.infirmiers);
		}
	}
}

<div class="di-carte-seance" [ngClass]="getClasses()">
	<div class="di-carte-seance__header">
		<div class="patient-info__container">
			<lua-objet-patient size="small" [firstName]="patient?.firstName" [lastName]="patient?.lastName"
				[info]="tailleXs ? '' : getAdressePatient(patient)"></lua-objet-patient>
		</div>
		<div class="tags-moment__container">
			<div *ngIf="tagsSoins" class="liste-tags">
				<lua-tag *ngFor="let tag of tagsSoins" [label]="tag.label" [color]="tag.color" [mode]="tag.mode"></lua-tag>
			</div>

			<strong>{{ moment }}</strong>
		</div>
	</div>
	<lua-divider-horizontal *ngIf="!tailleXs" size="small"></lua-divider-horizontal>
	<div *ngIf="!tailleXs">
		<div class="di-carte-seance__body">
			<strong class="title">{{ ordonnance?.description || '' }}</strong>
			<div *ngIf="seance.actes" class="actes-container">
				<ng-container *ngFor="let item of seance.actes">
					<di-acte [acte]="item" [ellipsis]="true">
					</di-acte>
				</ng-container>
			</div>
			<div *ngIf="seance.majorations" class="majorations-container">
				<ng-container *ngFor="let item of seance.majorations">
					<di-majoration [type]="item.type" [price]="item.price"></di-majoration>
				</ng-container>
			</div>
			<div *ngIf="seance.indemnites" class="indemnites-container">
				<ng-container *ngFor="let item of seance.indemnites">
					<di-majoration [indemnity]="true" [indemnityLabel]="item.type + item.label"
						[price]="item.price"></di-majoration>
				</ng-container>
			</div>
			<div *ngIf="commentaire" class="commentaire-container">
				<di-commentaire [commentaire]="commentaire"></di-commentaire>
			</div>
		</div>
		<div class="di-carte-seance__footer">
			<di-infirmier *ngIf="infirmier" [infirmier]="infirmier" [date]="dateDeRealisation" [showMenu]="false"
				color="CouleurPrimaire"></di-infirmier>
			<di-infirmier *ngIf="!infirmier" color="Noir20" [showMenu]="false"></di-infirmier>

			<div *ngIf="seance.status == 5" class="facture">
				<strong>FACTURÉ</strong>
				<lua-icone iconName="check_circle" color="CouleurPrimaire" mode="outlined"></lua-icone>
			</div>
			<lua-bouton-icone *ngIf="seance.status != 5" iconName="More_Vert" size="medium" color="CouleurPrimaire"
				mode="filled" [isDisabled]="disabledIcon" (click)="openMenu($event)"></lua-bouton-icone>
		</div>
	</div>
	<div *ngIf="tailleXs" class="small_card_content">
		<lua-avatar *ngIf="infirmier" size="medium" [firstName]="infirmier.firstName" [lastName]="infirmier.lastName"
			[color]="getAvatarColor()" [withTooltip]="false"></lua-avatar>
		<strong> {{ getTailleXsBody() }} </strong>
	</div>
</div>
<div class="authenticator-container">
	<div class="login-container">
		<div class="login-content">
			<div class="login-titre">
				<div class="login-titre-web">
					<h1>Connexion</h1>
					<small>Entrez vos informations de connexion</small>
				</div>
				<div class="login-titre-mobile">
					<!-- <mat-icon svgIcon="desmos"></mat-icon> -->
					<lua-icone [isIcon]="true" width="100px" height="100px" color="CouleurPrimaire"
						customIconName="desmos"></lua-icone>
					<h1>DESMOS</h1>
					<div class="sous-titre">Infirmiers</div>
				</div>
			</div>
			<div class="login-form">
				<lua-input [value]="email" [fullWidth]="true" label="Email de connexion"
					(valueChange)="handleChangeEmail($event)" placeholder="Quel est votre email de connexion ?"
					[isError]="isEmailError">
				</lua-input>
				<lua-input [value]="password" [fullWidth]="true" label="Mot de passe"
					(valueChange)="handleChangePassword($event)" [type]="passwordInputType" iconToolTip="afficher le mot de passe"
					(clickIcon)="handleClickIcon($event)" [iconEnd]="passwordIcon" placeholder="Quel est votre mot de passe ?"
					[isError]="isPasswordError" modeIcon="outlined">
				</lua-input>
				<lua-alerte *ngIf="errorConnexion$ | async as errorMessage" [filled]="true" [fullWidth]="true" type="error"
					iconName="info" [message]="errorMessage"></lua-alerte>
				<lua-action-principale [fullWidth]="true" libelle="Connexion"
					(click)="validateUser(email, password)"></lua-action-principale>
				<lua-bouton-texte [fullWidth]="true" libelle="Mot de passe perdu ?"
					(click)="navigateToMotDePassePerdu()"></lua-bouton-texte>
			</div>
		</div>
	</div>
	<di-side-page-desmos colorTitre="Blanc" colorLogo="Blanc" colorBackground="DegradeOrisha2Couleurs"
		[showBackgroundImage]="false"></di-side-page-desmos>
</div>
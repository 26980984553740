import { Component, OnInit } from '@angular/core';
import { ArrayHelper, DateHelper, IdHelper } from '@osapp/helpers';
import { EPrefix, UserData } from '@osapp/model';
import { ETimetablePattern } from '@osapp/model/date/ETimetablePattern';
import { DestroyableComponentBase } from '@osapp/modules/utils/components/destroyable-component-base';
import { Observable, forkJoin } from 'rxjs';
import { mergeMap, switchMap, takeUntil, tap } from 'rxjs/operators';
import { IPatient } from '../../../modules/patients/model/IPatient';
import { ITransmissionRapport } from '../../../modules/patients/model/ITransmissionRapport';
import { PatientsService } from '../../../modules/patients/services/patients.service';
import { RapportService } from '../../../modules/patients/services/rapport.service';
import { DeviceService } from '../../features/shared/services/device.service';
import { HistoriqueService } from '../../features/shared/services/historique.service';
import { PanneauService } from '../../features/shared/services/panneau.service';
import { TransmissionService } from '../../features/shared/services/transmission.service';
import { PanneauDetailTransmissionComponent } from '../../features/transmissions/components/panneau-detail-transmission/panneau-detail-transmission.component';
import { Historique } from '../../models/notifications/Historique';
import { NB_ITEM_TO_DISPLAY } from '../../anakin.constants';

@Component({
	selector: 'di-transmission',
	templateUrl: './transmissions.page.html',
	styleUrls: ['./transmissions.page.scss'],
})
export class TransmissionsPage extends DestroyableComponentBase implements OnInit {

	public showSearchPatient: boolean = false;
	public searchedValue: string = "";
	public transmissions: ITransmissionRapport[] = [];
	public filteredTransmissions: ITransmissionRapport[] = [];
	public aucuneTransmission: boolean = false;
	public transmissionCountMessage: string = "";
	public isMobileView: boolean = false;
	private patientIds: string[];
	public patientsMap: Map<string, IPatient> = new Map();
	public MainTextNoResult: string;
	public SubTextNoResult: string;
	public historique: Historique;
	public userId: string;
	public userIdSansPrefix: string;
	public displayCount: number = NB_ITEM_TO_DISPLAY;


	constructor(
		private svcDevice: DeviceService,
		private svcTransmission: RapportService,
		private svcPatient: PatientsService,
		private svcPanneau: PanneauService,
		private svcTransmissionService: TransmissionService,
		private readonly svcHistorique: HistoriqueService,
	) { super(); }

	async ngOnInit() {
		this.userId = UserData.current?.name || "";
		this.userIdSansPrefix = IdHelper.extractIdWithoutPrefix(this.userId, EPrefix.user);
		const siteId: string = UserData.currentSite._id || "";

		this.svcHistorique.getByUserAndSite(this.userId, siteId).pipe(
			tap((historique) => {
				this.historique = historique;
				this.loadListTransmission();
			}),
			mergeMap((historique) => this.svcHistorique.setLastVisitedTransmission(historique))
		).subscribe();

		this.svcDevice.isMobile$.pipe(takeUntil(this.destroyed$)).subscribe((flag: boolean) => {
			this.isMobileView = flag;
		});

		this.svcTransmissionService.deletion$.pipe(takeUntil(this.destroyed$)).subscribe(() => {
			this.loadListTransmission();
		});

		this.svcPanneau.closePanel$.pipe(takeUntil(this.destroyed$)).subscribe(() => {
			this.loadListTransmission();
		});
	}

	private loadListTransmission(): void {
		this.initTransmissions().pipe(
			tap(() => {
				this.filteredTransmissions = this.transmissions;
				this.getNbNewTransmission();
				this.aucuneTransmission = this.filteredTransmissions.length === 0;
				if (this.aucuneTransmission) {
					this.MainTextNoResult = "Aucune transmission";
					this.SubTextNoResult = "Vous allez retrouver ici toutes les transmissions par patient.";
				}
			}),
			switchMap(() => {
				this.patientIds = Array.from(new Set(this.filteredTransmissions.map(trans => {
					return this.getIdPatient(trans._id);
				}).filter(id => id)));
				return forkJoin({
					patients: this.svcPatient.getPatientsByIds(this.patientIds)
				});
			}),
			tap(({ patients }) => {
				this.patientsMap = new Map(patients.map(patient => [patient._id, patient]));
			})
		).subscribe();
	}

	public getIdPatient(string: string): string {
		return `${EPrefix.patient}${IdHelper.getGuidFromId(string, EPrefix.patient).split(IdHelper.C_ID_SEPARATOR)[0]}`;
	}

	public getPatientFromId(id: string): IPatient {
		return this.patientsMap.get(this.getIdPatient(id));
	}

	private initTransmissions(): Observable<ITransmissionRapport[]> {
		return this.svcTransmission.getTransmissionsAnakin(false).pipe(
			tap((transmissions: ITransmissionRapport[]) => {
				this.transmissions = transmissions.map(transmission => {
					const dateTrans: Date = transmission.dateLastModification ?? transmission.createdDate;
					return {
						...transmission,
						dateLastModification: new Date(dateTrans)
					};
				});

				this.transmissions.sort((a, b) => {
					return (b.dateLastModification as Date).getTime() - (a.dateLastModification as Date).getTime();
				});
			})
		);
	}

	public isNewTag(transmission: ITransmissionRapport): boolean {
    const isAfterLastVisited = DateHelper.compareTwoDates(transmission.dateLastModification, this.historique.lastVisitedTransmission) > 0;
    if (isAfterLastVisited) {
        const isAuthor = IdHelper.getGuidFromId(transmission.authorPath, EPrefix.contact) === this.userIdSansPrefix;

        if (!isAuthor) {
            if (ArrayHelper.hasElements(transmission.commentaires)) {
                const lastComment = ArrayHelper.getLastElement(transmission.commentaires);
                const isLastCommentAuthor = lastComment?.auteurId === IdHelper.buildId(EPrefix.contact, this.userIdSansPrefix);

                if (isLastCommentAuthor) {
                    return false;
                }
            }
            return true;
        }
        if (ArrayHelper.hasElements(transmission.commentaires)) {
            const lastComment = ArrayHelper.getLastElement(transmission.commentaires);
            const isLastCommentAuthor = lastComment?.auteurId === IdHelper.buildId(EPrefix.contact, this.userIdSansPrefix);
            if (!isLastCommentAuthor) {
                return true;
            }
        }
    }
    return false;
	}




	public getNbNewTransmission(): void {
		const nbTransmission = this.filteredTransmissions.length;
		if (nbTransmission == 0) {
			this.transmissionCountMessage = "Aucune transmission";
		}
		else {
			this.transmissionCountMessage = `${nbTransmission} nouvelle${nbTransmission > 1 ? "s" : ""} transmission${nbTransmission > 1 ? "s" : ""}`;
		}
	}

	public handleAddTransmissionClick(event: Event) {
		this.svcPanneau.open("Patients", PanneauDetailTransmissionComponent, {})
	}

	public handleSearchClick(event: Event) {
		this.showSearchPatient = !this.showSearchPatient
	}

	public handlePrecedentTransmissionClick(event: Event) {
		this.displayCount += NB_ITEM_TO_DISPLAY;
	}

	public filterPatients(searchValue: string) {
		this.searchedValue = searchValue.trim().toLowerCase();

		if (this.searchedValue && this.searchedValue.length > 2) {
			const searchTerms = this.searchedValue.split(' ').filter(term => term);

			this.filteredTransmissions = this.transmissions.filter(transmission => {
				const patient = this.patientsMap.get(this.getIdPatient(transmission._id));
				if (!patient) return false;

				const firstName = patient.firstName ? patient.firstName.toLowerCase() : '';
				const lastName = patient.lastName ? patient.lastName.toLowerCase() : '';

				const matchesOnlyFirstName = searchTerms.length === 1 &&
					searchTerms[0] && firstName.includes(searchTerms[0]);

				const matchesOnlyLastName = searchTerms.length === 1 &&
					searchTerms[0] && lastName.includes(searchTerms[0]);

				const matchesFirstLast = searchTerms.length === 2 &&
					firstName.includes(searchTerms[0]) && lastName.includes(searchTerms[1]);

				const matchesLastFirst = searchTerms.length === 2 &&
					lastName.includes(searchTerms[0]) && firstName.includes(searchTerms[1]);

				return matchesOnlyFirstName || matchesOnlyLastName || matchesFirstLast || matchesLastFirst;
			});
		} else {
			this.filteredTransmissions = this.transmissions;
		}
		this.aucuneTransmission = this.filteredTransmissions.length === 0;
		if (this.aucuneTransmission) {
			this.MainTextNoResult = "Aucune transmission trouvée";
			this.SubTextNoResult = "Essayer de modifier votre recherche";
		}
		this.getNbNewTransmission();
	}

	public formatDate(date: Date): string {
		const today = new Date();
		const dateIso = new Date(date);
		const isToday = dateIso.toDateString() === today.toDateString();

		const formattedDate = DateHelper.transform(dateIso, ETimetablePattern.EEEE_dd_MMMM);

		if (isToday) {
			return `Aujourd’hui, ${formattedDate}`;
		} else {
			return formattedDate;
		}
	}

	public isDateDifferent(index: number): boolean {
		if (index === 0) return true;
		const currentDate = new Date(this.filteredTransmissions[index].dateLastModification);
		const previousDate = new Date(this.filteredTransmissions[index - 1].dateLastModification);
		return currentDate.toDateString() !== previousDate.toDateString();
	}
}

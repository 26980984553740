import { Injectable } from '@angular/core';
import { DateHelper } from '@osapp/helpers';
import { EDatabaseRole, EPrefix, IDataSource, IStoreDataResponse, IStoreDocument } from '@osapp/model';
import { Store } from '@osapp/services/store.service';
import { Observable, of, Subject } from 'rxjs';
import { catchError, map, mergeMap, tap } from 'rxjs/operators';
import { Evenement } from '../../../models/Evenement';



@Injectable({
  providedIn: 'root',
})
export class EvenementService {
  constructor(private isvcStore: Store) {}
  private refreshEventSubject = new Subject<void>();
    refreshEventList$ = this.refreshEventSubject.asObservable();

    triggerRefreshEventList() {
        this.refreshEventSubject.next();
    }
  /** Créer ou mettre à jour un évènement
   * @param event L'évènement à créer ou mettre à jour
   */
  public createOrUpdate(event: Evenement) {
   return this.isvcStore.put(event).pipe(
    tap(() => {
        this.triggerRefreshEventList();
    }),
    mergeMap((response: IStoreDataResponse) => {
        return of(response.ok)
    }
    )
)
  }

  /** Obtenir un évènement par l'ID
   * @param eventId ID de l'évènement
   */
  public getById(eventId: string): Observable<Evenement> {
    const loDataSource = {
      databaseId: this.isvcStore.getDatabasesIdsByRole(EDatabaseRole.workspace)[0],
      viewParams: {
        include_docs: true,
        keys: [eventId],
      },
    };

    return this.isvcStore.getOne(loDataSource).pipe(
        map((doc: IStoreDocument) => {
            return doc as Evenement;
        }),
      catchError((error) => {
        console.error('Erreur lors de la récupération :', error);
        return of(null);
      })
    );
  }




  /** Supprimer un évènement
   * @param event L'évènement à supprimer
   */
  public delete(event: Evenement): Observable<any> {
    return this.isvcStore.delete(event).pipe(
      tap(() => {
        this.triggerRefreshEventList();
    }),
      catchError((error) => {
        console.error('Erreur lors de la suppression :', error);
        return of(null);
      })
    );
  }

  
  private createEventDataSource(filterFn: (event: Evenement) => boolean): IDataSource {
    return {
        databasesIds: this.isvcStore.getDatabasesIdsByRole(EDatabaseRole.workspace),
        viewParams: {
            include_docs: true,
            startkey: `${EPrefix.evenement}`,
            endkey: `${EPrefix.evenement}${Store.C_ANYTHING_CODE_ASCII}`,
        },
        filter: filterFn
    };
}


  public getEventsByInfirmier(infirmierId: string): Observable<Evenement[]> {
    const dataSource = this.createEventDataSource(
      (event: Evenement) => (event.infirmierId === infirmierId || event.infirmierId === "")
  );
    return this.isvcStore.get<Evenement>(dataSource).pipe(
        map(events => this.sortEventsChronologically(events)) // If sorting is needed
    );
}
private sortEventsChronologically(events: Evenement[]): Evenement[] {
    return events.sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime());
}
 // Récupère les events qui commencent à la date donnée
 public getEventByDate(date: Date): Observable<Evenement[]> {
  const startOfDay = DateHelper.resetDay(date);
  const endOfDay = DateHelper.fillDay(date);
  const dataSource = this.createEventDataSource(
      (event: Evenement) => DateHelper.isBetweenTwoDates(event.date, startOfDay, endOfDay)
  );
    return this.isvcStore.get<Evenement>(dataSource).pipe(
        map(events => this.sortEventsChronologically(events))
    );
}

 // Récupère les events qui commencent sur l'intervalle de date donné
 public getEventByRange(startDate: Date, endDate: Date): Observable<Evenement[]> {
  const dataSource = this.createEventDataSource(
    (event: Evenement) => DateHelper.isBetweenTwoDates(event.date, DateHelper.resetDay(startDate), DateHelper.fillDay(endDate))
);
   
    return this.isvcStore.get<Evenement>(dataSource).pipe(
        map(events => this.sortEventsChronologically(events))
    );
}



 // Récupère les séances affectés à l'infirmier ainsi que celles non affectées pour un jour donné
 public selectEventsByDateAndInfirmier(date: Date, infirmierIds: string[]): Observable<Evenement[]> {
  const startOfDay = DateHelper.resetDay(date);
  const endOfDay = DateHelper.fillDay(date);
  const dataSource = this.createEventDataSource(
      (event: Evenement) => ((infirmierIds.some(id => event.infirmierId.includes(id)) || event.infirmierId === "") && DateHelper.isBetweenTwoDates(event.date, startOfDay, endOfDay))
  );
  return this.isvcStore.get<Evenement>(dataSource).pipe(
      map(events => this.sortEventsChronologically(events))
  );
}



// Récupère les séances sur une plage de date donnée en fonction de l'infirmier choisi
public selectEventsByRangeAndInfirmier(startDate: Date, endDate: Date, infirmierId: string): Observable<Evenement[]> {
  const dataSource = this.createEventDataSource(
      (event: Evenement) => ((event.infirmierId === infirmierId || event.infirmierId === "") && DateHelper.isBetweenTwoDates(event.date, DateHelper.resetDay(startDate), DateHelper.fillDay(endDate)))
  );
  return this.isvcStore.get<Evenement>(dataSource).pipe(
      map(events => this.sortEventsChronologically(events))
  );
}
}

import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { DateHelper } from '@osapp/helpers';
import { ETimetablePattern } from '@osapp/model';
import { DestroyableComponentBase } from '@osapp/modules/utils/components/destroyable-component-base';
import { ETraitementState } from 'apps/idl/src/model/ETraitementState';
import { Traitement } from 'apps/idl/src/model/Traitement';
import { TraitementService } from 'apps/idl/src/services/traitement.service';
import { forkJoin } from 'rxjs';
import { finalize, switchMap, takeUntil, tap } from 'rxjs/operators';
import { IPatient } from '../../../modules/patients/model/IPatient';
import { PatientsService } from '../../../modules/patients/services/patients.service';
import { NB_ITEM_TO_DISPLAY } from '../../anakin.constants';
import { DeviceService } from '../../features/shared/services/device.service';
import { LoaderService } from '../../features/shared/services/loader.service';

@Component({
  selector: 'di-facturation',
  templateUrl: './facturation.page.html',
  styleUrls: ['./facturation.page.scss'],
})
export class FacturationPage extends DestroyableComponentBase implements OnInit {

	public ordoActiveCountMessage :string;
  public dateFacturation: Date = new Date();
  public facturationfilters: Array<string> = ["A FACTURER", "FACTURÉES"];
  public defaultFilter = this.facturationfilters[0];
  public ordonnances: Traitement[] = [];
	public filteredOrdonnance: Traitement[] = [];
  public isMobile: boolean = false;
  public displayCount: number = NB_ITEM_TO_DISPLAY;
	public patientsMap: Map<string, IPatient> = new Map();
  public filtreForm: FormGroup;
	public searchedValue : string = "";
	private patientIds :string[];
	public aucuneOrdonnance : boolean = false;
	public MainTextNoResult : string = "Aucune ordonnance";
	public SubTextNoResult : string = "Vous allez retrouver ici toutes les ordonnances patients en cours.";




  constructor(
    private svcTraitement: TraitementService,
    private svcDevice: DeviceService,
    private fb: FormBuilder,
    private router: Router,
    private svcLoader: LoaderService,
		private svcPatient : PatientsService
  ) { super(); }

  ngOnInit() {
    this.filtreForm = this.fb.group({
      dateFacturation: [this.dateFacturation ? this.dateFacturation : new Date(), []],
    });

    // Actualise la liste des ordonnances quand l'une d'elles a été supprimées
    this.svcTraitement.deletion$.pipe(takeUntil(this.destroyed$)).subscribe(() => {
      this.loadListOrdonnances();
    });


    this.svcDevice.isMobile$
      .pipe(takeUntil(this.destroyed$))
      .subscribe((flag: boolean) => {
        this.isMobile = flag;
      });
		
    this.loadListOrdonnances();
  }

	private loadListOrdonnances() {
		this.svcLoader.showLoader();
		//Récupérer les ordonnances avec les factu
		this.svcTraitement.getTraitementsByStatus(false, null, ETraitementState.termine, (this.defaultFilter != "FACTURÉES"))
			.pipe(
				tap((ordo) => {
					this.ordonnances = ordo;
					this.filteredOrdonnance = this.ordonnances
					this.getNbOrdonnance()
				}),
				switchMap(() => {
					this.patientIds = Array.from(new Set(this.ordonnances.map(ordo => {
						return Traitement.extractPatientId(ordo._id);
					}).filter(id => id)));
					return forkJoin({
						patients: this.svcPatient.getPatientsByIds(this.patientIds)
					});
				}),
				tap(({patients}) => {
					this.patientsMap = new Map(patients.map(patient => [patient._id, patient]));
				}),
				tap(_ => {
					if(this.searchedValue !== "")
					{
						this.filterOrdonnances(this.searchedValue);
					}
				}),
				takeUntil(this.destroyed$),
				finalize(() => {
					this.svcLoader.hideLoader();
				})
			)
			.subscribe();
	}

  async changeFilter(filtreSelected: string) {
		this.defaultFilter = filtreSelected;
		this.loadListOrdonnances();
  }

  onValueChange(newDate: string) {
    const newDateFormated = new Date(newDate);
    this.dateFacturation = newDateFormated;
    this.filtreForm.setValue({
      dateFacturation: newDateFormated,
    });
  }

  handleFacturer = (idOrdonnance: string): void => {
    if (!idOrdonnance) return;
    // this.router.navigate(["facturation", "controle", idOrdonnance]);
    this.router.navigate(["facturation", "controle", idOrdonnance], { state: { dateFacturation: this.dateFacturation } });
  }

  
  showMore() {
    this.displayCount += NB_ITEM_TO_DISPLAY;
  }

	public filterOrdonnances(searchValue: string) {
		this.searchedValue = searchValue.trim().toLowerCase();
    if (this.searchedValue && this.searchedValue.length > 2) {
        const searchTerms = this.searchedValue.split(' ').filter(term => term);
        this.filteredOrdonnance = this.filteredOrdonnance.filter(ordonnance => {
            const patient = this.patientsMap.get(Traitement.extractPatientId(ordonnance._id));
            if (!patient) return false;

            const firstName = patient.firstName ? patient.firstName.toLowerCase() : '';
            const lastName = patient.lastName ? patient.lastName.toLowerCase() : '';
						const formattedBirthDateSlash = DateHelper.transform(patient.birthDate, ETimetablePattern.dd_MM_yyyy_slash);

            const matchesOnlyFirstName = searchTerms.length === 1 &&
                searchTerms[0] && firstName.includes(searchTerms[0]);

            const matchesOnlyLastName = searchTerms.length === 1 &&
                searchTerms[0] && lastName.includes(searchTerms[0]);

            const matchesFirstLast = searchTerms.length === 2 &&
                firstName.includes(searchTerms[0]) && lastName.includes(searchTerms[1]);

            const matchesLastFirst = searchTerms.length === 2 &&
                lastName.includes(searchTerms[0]) && firstName.includes(searchTerms[1]);

					const matchesSocialNumber = patient.socialNumber?.startsWith(this.searchedValue);
					const matchesBirthDate = this.searchedValue === formattedBirthDateSlash;

					return matchesOnlyFirstName || matchesOnlyLastName || matchesFirstLast || matchesLastFirst || matchesSocialNumber || matchesBirthDate;
        });
    } else {
				if(searchValue === ""){
					this.filteredOrdonnance = this.ordonnances;
				}
        this.filteredOrdonnance = this.filteredOrdonnance;
    }
		this.aucuneOrdonnance = this.filteredOrdonnance.length === 0;
		if(this.aucuneOrdonnance)
		{
			this.MainTextNoResult = "Aucune ordonnance trouvée";
			this.SubTextNoResult = "Essayer de modifier votre recherche";
		}
		this.getNbOrdonnance();
  }

	public getNbOrdonnance() : void {
    const nbOrdoActive = this.filteredOrdonnance.length ;
		if(nbOrdoActive == 0)
		{
			this.ordoActiveCountMessage = "Aucune ordonnance";
		}
		else
		{
    	this.ordoActiveCountMessage = `${nbOrdoActive} ordonnance${nbOrdoActive > 1 ? "s actives" : " active"}`;
		}
  }

	public showOrdonnanceList() {
    return this.filteredOrdonnance.length > 0;
  }
}


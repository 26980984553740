import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DestroyableComponentBase } from '@osapp/modules/utils/components/destroyable-component-base';
import { IPatient } from 'apps/idl/src/modules/patients/model/IPatient';
import { PatientsService } from 'apps/idl/src/modules/patients/services/patients.service';
import { TabComponent } from 'lighting-up-angular';
import { Observable, forkJoin, of } from 'rxjs';
import { delay, map, mergeMap, switchMap, takeUntil, tap } from 'rxjs/operators';
import { ITraitement } from '../../../../model/ITraitement';
import { Traitement } from '../../../../model/Traitement';
import { TraitementService } from '../../../../services/traitement.service';
import { MenuOrdonnanceComponent } from '../../../features/ordonnances/components/menu-ordonnance/menu-ordonnance.component';
import { DrawerPopoverService } from '../../../features/shared/services/drawer-popover.service';
import { SeanceService } from '../../../features/shared/services/seance.service';
import { SeancesGeneratorService } from '../../../features/shared/services/seances-generator.service';
import { StoredSeance } from '../../../models/StoredSeance';
import { IContact } from '@osapp/model';
import { EStatusSeance } from 'apps/idl/src/model/EStatusSeance';
import { LoaderService } from '../../../features/shared/services/loader.service';

@Component({
	selector: 'di-detail-ordonnance',
	templateUrl: './detail-ordonnance.page.html',
	styleUrls: ['./detail-ordonnance.page.scss'],
})
export class DetailOrdonnancePage extends DestroyableComponentBase implements OnInit {

	constructor(
		private svcPatients: PatientsService,
		private svcOrdonnance: TraitementService,
		private router: Router,
		private route: ActivatedRoute,
		private svcSeancesGenerator: SeancesGeneratorService,
		private svcDrawerPopover: DrawerPopoverService,
		private svcSeance: SeanceService,
		private svcLoader: LoaderService
	) {
		super();
	}
	public showPopover: boolean;

	public mode: "add" | "edit" = "add";
	public patientId: string;
	public currentStep: number = 0;
	public patient: IPatient;
	public medecin: IContact;
	public ordonnance?: ITraitement;
	public stepNames = ['Ordonnance', 'Actes et soins', 'Séances']
	public showTabActe: boolean = false;
	public showTabSeances: boolean = false;
	public showTabFactures: boolean = false;
	public tabSelected: string = "";
	idOrdonnance!: string;
	public seances: StoredSeance[] = [];

	public generatingSeance: boolean = false;

	ngOnInit(): void {
		
		this.idOrdonnance = this.route.snapshot.paramMap.get('id')!;
		this.getTabSelectedFromRoute();

		if (this.idOrdonnance) {
			this.svcLoader.showLoader("Récupération des informations des l'ordonnance...");
			this.svcOrdonnance.getTraitementANAKIN(this.idOrdonnance).pipe(
				tap((ordonnance: Traitement) => {
					this.ordonnance = ordonnance;
					this.patientId = Traitement.extractPatientId(this.idOrdonnance);
					this.getPatient(this.patientId);
				}),
				switchMap(() => this.svcSeance.selectSeancesByTraitement(this.ordonnance)),
				tap((seances: StoredSeance[]) => {
					this.seances = seances;
					this.showTabSeances = this.tabSelected === "Séances";
					this.svcLoader.hideLoader();
				}),
				takeUntil(this.destroyed$)
			).subscribe();
			// On récupère le médecin prescripteur dans le cas d'une redirection depuis le form-contact
			this.medecin = history.state?.medecin;
			this.mode = "edit";
		}
		else {
			// On récupère le patient à qui va être lié l'ordonnance
			const state = this.router.getCurrentNavigation()?.extras.state as { patientId: string };
			this.patientId = state?.patientId;
			if (!this.patientId) {
				this.patientId = history.state?.patientId;
			}
			this.getPatient(this.patientId);
			// On récupère le médecin prescripteur
			this.medecin = history.state?.medecin;
		}
	}

	private getTabSelectedFromRoute() {
		const state = this.router.getCurrentNavigation()?.extras.state as { tabSelected: string; };
		this.tabSelected = state?.tabSelected;
		if (!this.tabSelected) {
			this.tabSelected = history.state.tabSelected;
		}
	}

	private getPatient(patientId: string) {
		this.svcPatients.getPatient(patientId).pipe(
			tap((patient: IPatient) => {
				this.patient = patient;
			}),
			takeUntil(this.destroyed$)
		).subscribe();
	}
	onStepChange(stepIndex: number): void {
		this.currentStep = stepIndex - 1;  // Adjust step index if needed
	}


	public handleSaveOrdonnance(ordo: Traitement) {
		this.ordonnance = ordo;
		if (this.mode === "add") {
			this.currentStep++;
		}
		if (this.mode === "edit") {
			this.router.navigate(['ordonnances']);
		}
	}

	public handleGenerateSeance() {
		if (this.mode === "add") {
			this.generateSeances().subscribe();

		}
		if (this.mode === "edit") {
			this.deleteAllSeanceAndGenerate();
		}
	}

	public openMenu(event: Event): void {
		this.showPopover = true;
		const menuOrdonnanceInputs = {
			edit: false, 
			ordonnance: this.ordonnance,
			hasSeanceFacturee: this.seances.some((seance: StoredSeance) => seance.status === EStatusSeance.completed)
		}
		this.svcDrawerPopover.open("", "50%", event.currentTarget, MenuOrdonnanceComponent, menuOrdonnanceInputs, () => this.showPopover = false)
	}


	handleTabSelection(tab: TabComponent) {
		this.showTabActe = tab && tab.label === "Actes";
		this.showTabSeances = tab && tab.label === "Séances";
		this.showTabFactures = tab && tab.label === "Factures";
	}

	public generateSeances(seancesNotDeleted?: StoredSeance[]): Observable<Boolean> {
		return this.svcOrdonnance.getTraitement(this.ordonnance?._id)
			.pipe(
				tap((traitement: Traitement) => {
					if (this.mode === 'add') {
						this.currentStep++;
					}
					this.ordonnance = traitement;
				}),
				switchMap((traitement: Traitement) => this.svcSeancesGenerator.saveSeances(traitement, this.patient, seancesNotDeleted)),
				delay(500),//TODO: a supprimer si trop long, simule une latence pour le moment
				tap(() => {
					if (this.mode === 'add') {
						this.router.navigate(
							['ordonnances/edit/', this.ordonnance._id],
							{ state: { tabSelected: "Séances" } });
					}
				}),
				takeUntil(this.destroyed$)
			);
	}


	public deleteAllSeanceAndGenerate(): void {
		// Pour éviter que la génération des séances ne soient lancées plusieurs fois
		if(this.generatingSeance) return;

		this.tabSelected = 'Actes';
		this.showTabSeances = false;
		this.showTabActe = true;
		let seancesNotDeleted: StoredSeance[] = []
		this.generatingSeance = true;
		this.svcSeance.selectSeancesByTraitement(this.ordonnance).pipe(
			map((listSeances: StoredSeance[]) => {
				// Parmis les séances existantes, on stocke les séances facturées ou réalisées pour ne pas les générer de nouveau
				const result = listSeances.reduce((acc, seance: StoredSeance) => {
					if (seance.status === EStatusSeance.completed || seance.status === EStatusSeance.done) {
						acc.seancesNotDeleted.push(seance);
					} else {
						acc.seancesToDelete.push(seance);
					}
					return acc;
				}, { seancesNotDeleted: [], seancesToDelete: [] });

				seancesNotDeleted = result.seancesNotDeleted;
				return result.seancesToDelete;
			}),
			mergeMap((listSeance: StoredSeance[]) => {
				if (listSeance.length === 0) {
					return of(null);
				}
				const deleteSeanceObservables = listSeance.map(seance =>
					this.svcSeance.deleteSeance(seance)
				);
				return forkJoin(deleteSeanceObservables);
			}),
			switchMap(() => this.generateSeances(seancesNotDeleted)),
			tap(() => {
				this.tabSelected = 'Séances';
				this.showTabSeances = true;
				this.showTabActe = false;
				this.generatingSeance = false;
			}),
			takeUntil(this.destroyed$)
		).subscribe();
	}

}
import { Component, ElementRef, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from "@angular/core";
import { DateHelper } from "@osapp/helpers";
import { map, switchMap, tap } from "rxjs/operators";
import { EStatusSeance } from "../../../../../model/EStatusSeance";
import { IActe } from "../../../../../model/IActe";
import { ITraitement } from "../../../../../model/ITraitement";
import { Traitement } from "../../../../../model/Traitement";
import { IPatient } from "../../../../../modules/patients/model/IPatient";
import { PatientsService } from "../../../../../modules/patients/services/patients.service";
import { StoredSeance } from "../../../../models/StoredSeance";
import { EEtatFacturationSeance } from "../../../shared/enums/EEtatFacturationSeance";
import { DrawerPopoverService } from "../../../shared/services/drawer-popover.service";
import { SeanceService } from "../../../shared/services/seance.service";
import { MenuOrdonnanceComponent } from "../menu-ordonnance/menu-ordonnance.component";
import { ETraitementState } from "apps/idl/src/model/ETraitementState";
import { ETimetablePattern } from "@osapp/model";
import { EPlace } from "apps/idl/src/model/EPlace";
import { forkJoin, Observable, of } from "rxjs";

@Component({
	selector: "di-carte-ordonnance",
	templateUrl: "./carte-ordonnance.component.html",
	styleUrls: ["./carte-ordonnance.component.scss"],
})
export class CarteOrdonnanceComponent implements OnInit, OnChanges {
	@ViewChild('menuOrdonnance', { static: false }) menuOrdonnance!: ElementRef<HTMLDivElement>;
	
	@Input() ordonnance?: ITraitement;
	@Input() dateFacturation: Date;
	@Input() isNew = false;
	@Input() modeFacturation = false;
	@Input() onFacturerClick: (idOrdonnance: string) => void;
	
	public etatFacturation: EEtatFacturationSeance = EEtatFacturationSeance.Default;
	public aFacturer: number = 0;
	public finishDate = "";
	public status: "active" | "finished" = "active";
	public inCabinet = false;
	public ordonnanceObj?: Traitement;
	public patient: IPatient;
	public patientId: string;
	public showPopover: boolean;
	public actes: IActe[] = [];
	
	public doneSeances: number = 0;
	public completedSeances: number = 0;
	public totalSeances: number = 0;

	// Nombre de séances completed ou done
	public currentSeance: number = 0;
	
	constructor(
		private svcPatient: PatientsService,
		private svcDrawerPopover: DrawerPopoverService,
		private svcSeance: SeanceService
	) { }

	ngOnInit() {
		this.initializeActes();
		this.extractAndFetchPatient();
		this.initializeOrdonnance();
		this.fetchSeances();
		this.setInCabinet();
	}

	ngOnChanges(changes: SimpleChanges): void {
		if (changes['dateFacturation']) {
			if (this.modeFacturation)
				this.initSeanceFacturation().subscribe((etat: EEtatFacturationSeance) => {
					this.etatFacturation = etat;
				});
		}
	}

	private initSeanceFacturation(): Observable<EEtatFacturationSeance> {
		if (this.totalSeances === 0) return of(EEtatFacturationSeance.None);
		if (this.ordonnance?.state === ETraitementState.termine) {
			this.status = "finished";
			return of(EEtatFacturationSeance.All);
		}

		const dateFacturationFinJournee = DateHelper.fillDay(this.dateFacturation ?? new Date());

		//Ici on récupère les compteurs à partir d'une date de facturation (pour la page de facturation, différent des compteurs des séances de la carte ordonnance)
		return forkJoin({
			done: this.svcSeance.countSeancesByStatusAndDateFacturation(this.ordonnance, EStatusSeance.done, dateFacturationFinJournee),
			toBeDone: this.svcSeance.countSeancesByStatusAndDateFacturation(this.ordonnance, EStatusSeance.to_be_done, dateFacturationFinJournee),
			cancelled: this.svcSeance.countSeancesByStatusAndDateFacturation(this.ordonnance, EStatusSeance.canceled, dateFacturationFinJournee)
		}).pipe(
			tap(({ done, toBeDone, cancelled }) => {
				this.aFacturer = done + toBeDone + cancelled;
			}),
			map(({ done, toBeDone, cancelled }) => {
				if (done - (toBeDone + cancelled) === 0) {
					return EEtatFacturationSeance.None;
				} else {
					return EEtatFacturationSeance.Some;
				}
			})
		);
	}
	
	private initDateFinTraitement() {
		if (this.ordonnance?.endDate && this.totalSeances > 0) {
			this.finishDate = DateHelper.transform(this.ordonnance?.endDate, ETimetablePattern.dd_MM_yyyy_slash);
		}
	}
	
	private initializeActes(): void {
		if (this.ordonnance?.actes) {
			this.actes = this.ordonnance.actes;
		}
	}

	private extractAndFetchPatient(): void {
		this.patientId = Traitement.extractPatientId(this.ordonnance._id);
		this.svcPatient.getPatient(this.patientId).pipe(
			tap((patient: IPatient) => this.patient = patient)
		).subscribe();
	}
	
	private initializeOrdonnance(): void {
		this.ordonnanceObj = Traitement.createFromData(this.ordonnance);
	}
	
	private fetchSeances(): void {
		forkJoin({
			done: this.svcSeance.countSeancesByStatus(this.ordonnanceObj, EStatusSeance.done),
			completed: this.svcSeance.countSeancesByStatus(this.ordonnanceObj, EStatusSeance.completed),
			total: this.svcSeance.countSeancesByTraitement(this.ordonnanceObj)
		}).pipe(
			tap(({ done, completed, total }) => {
				this.doneSeances = done;
				this.completedSeances = completed;
				this.totalSeances = total;
				this.currentSeance = this.doneSeances + this.completedSeances;
				if (this.modeFacturation)
					this.initSeanceFacturation().subscribe((etat: EEtatFacturationSeance) => {
						this.etatFacturation = etat;
					});
				this.initDateFinTraitement();
			})
		).subscribe();
	}
	
	
	handleFacturerClick() {
		if (this.ordonnance?.state !== ETraitementState.termine && this.aFacturer > 0 && this.onFacturerClick) {
			this.onFacturerClick(this.ordonnance._id);
		}
	}
	
	getSoinNumber() {
		const remaining = this.totalSeances - this.currentSeance;
		if (this.currentSeance === 0) {
			return "1er soin";
		}
		if (remaining === 1) {
			return "Dernier soin";
		}
		if (remaining > 1 && remaining <= 7) {
			return `J-${remaining}`;
		}
		return "";
	}
	
	getProgressColor() {
		if (Number(this.currentSeance) === 0 && Number(this.totalSeances) === 0) {
			return "Gris"
		}
		
		if (Number(this.currentSeance) > Number(this.totalSeances) / 2) {
			return "Raspberry";
		} else {
			return "CouleurPrimaire";
		}
	}
	
	public getAdressePatient(pat: IPatient): string {
		const addressParts: string[] = [];
		if (pat.street) {
			addressParts.push(pat.street);
		}
		if (pat.zipCode) {
			addressParts.push(pat.zipCode);
		}
		if (pat.city) {
			addressParts.push(pat.city);
		}
		
		return addressParts.join(' ');
	}
	
	public openMenu(): void {
		this.showPopover = true;
		const menuOrdonnanceInputs = { 
			ordonnance: this.ordonnance, 
			anchorElement: this.menuOrdonnance, 
			hasSeanceFacturee: this.completedSeances > 0 
		}
		this.svcDrawerPopover.open("", "30%", this.menuOrdonnance?.nativeElement, MenuOrdonnanceComponent, menuOrdonnanceInputs, () => this.showPopover = false)
	}
	
	public closeMenu(): void {
		this.showPopover = false;
		this.svcDrawerPopover.close();
	}

	private setInCabinet() {
		if(!this.ordonnance.actes) return;
		this.inCabinet = this.ordonnance.actes.some((acte: IActe) => acte.place === EPlace.center)
	}
}

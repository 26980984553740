import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { EPrefix, ETimetablePattern, IContact } from '@osapp/model';
import { DestroyableComponentBase } from '@osapp/modules/utils/components/destroyable-component-base';
import { ContactsService } from '@osapp/services';
import { EStatusSeance } from 'apps/idl/src/model/EStatusSeance';
import { ITraitement } from 'apps/idl/src/model/ITraitement';
import { Traitement } from 'apps/idl/src/model/Traitement';
import { InterventionStatementService } from 'apps/idl/src/modules/intervention-statement/intervention-statement.service';
import { IPatient } from 'apps/idl/src/modules/patients/model/IPatient';
import { AMCP } from 'apps/idl/src/modules/patients/model/amc-p';
import { AMOP } from 'apps/idl/src/modules/patients/model/amo-p';
import { EUpdateMode } from 'apps/idl/src/modules/patients/model/eupdate-mode.enum';
import { IAMC } from 'apps/idl/src/modules/patients/model/iamc';
import { IAMO } from 'apps/idl/src/modules/patients/model/iamo';
import { IConvention } from 'apps/idl/src/modules/patients/model/iconvention';
import { CouverturesService } from 'apps/idl/src/modules/patients/services/couvertures.service';
import { PatientsService } from 'apps/idl/src/modules/patients/services/patients.service';
import { TraitementService } from 'apps/idl/src/services/traitement.service';
import { of } from 'rxjs';
import { catchError, map, mergeMap, switchMap, takeUntil, tap } from 'rxjs/operators';
import { ArrayHelper } from '../../../../../../../libs/osapp/src/helpers/arrayHelper';
import { DateHelper } from '../../../../../../../libs/osapp/src/helpers/dateHelper';
import { FacturationSvComponent } from '../../../features/facturation/components/facturation-sv/facturation-sv.component';
import { GroupeListeSeancesComponent } from '../../../features/shared/components/groupe-liste-seances/groupe-liste-seances-component';
import { PanneauIdentitePatientComponent } from '../../../features/shared/components/panneaux/panneau-identite-patient/panneau-identite-patient.component';
import { PanneauOrdonnanceComponent } from '../../../features/shared/components/panneaux/panneau-ordonnance/panneau-ordonnance.component';
import { PanneauPatientAmcComponent } from '../../../features/shared/components/panneaux/panneau-patient-amc/panneau-patient-amc.component';
import { PanneauPatientAmoComponent } from '../../../features/shared/components/panneaux/panneau-patient-amo/panneau-patient-amo.component';
import { EControleEtat } from '../../../features/shared/enums/EControleEtat';
import { DeviceService } from '../../../features/shared/services/device.service';
import { DrawerPopoverService } from '../../../features/shared/services/drawer-popover.service';
import { PanneauService } from '../../../features/shared/services/panneau.service';
import { SeanceService } from '../../../features/shared/services/seance.service';
import { SeancesGeneratorService } from '../../../features/shared/services/seances-generator.service';
import { SnackbarService } from '../../../features/shared/services/snackbar.service';
import { StoredSeance } from '../../../models/StoredSeance';
import { ControleFacturation } from '../../../models/controle-facturation/ControleFacturation';
import { IControleBase } from '../../../models/controle-facturation/IControleBase';
import { IAccordPrealable } from 'apps/idl/src/modules/patients/model/IAccordPrealable';
import { AccordPrealableService } from 'apps/idl/src/modules/traitement/slides-traitement/accordPrealable/accordPrealable.service';
import { PanneauAccordPrealableComponent } from '../../../features/shared/components/panneaux/panneau-accord-prealable/panneau-accord-prealable.component';

@Component({
  selector: 'di-controle-facturation',
  templateUrl: './controle-facturation.component.html',
  styleUrls: ['./controle-facturation.component.scss'],
})
export class ControleFacturationPage extends DestroyableComponentBase implements OnInit {
  public isMobile: boolean = false;
  /**
   * Liste de toutes les séances du traitement
  */
  public seances: StoredSeance[] = [];
  /**
   * Liste des séances à facturer sur la plage définie dans la page de facturation
   */
  public seancesAFacturer: StoredSeance[] = [];
  public seancesFacturables: StoredSeance[] = [];
  public idOrdonnance: string = "";
  public ordonnance: ITraitement; //v2 : Traitement = Ordonnance
  public patient: IPatient;
  public isFacturable: boolean = true;
  public infirmiers: IContact[];
  public convention: IConvention;
  public etablissementsById: Map<string, IAMC | IAMO> = new Map();
  public controlesFacturation: ControleFacturation = new ControleFacturation();
  public dateFacturation: Date = new Date();
  public facturationStart: boolean = false;


  @ViewChild('scrollContainer') scrollContainer!: ElementRef;


  // Défini les conditions d'erreur du patient avec un ordre de priorité
  private erreursPatient = [
    { condition: (patient: IPatient) => !patient.birthDate, message: "Date de naissance non renseignée" },
    // { condition: (patient: IPatient) => !patient.carteVitale, message: "Carte vitale non lue" },
    //TODO: à voir avec les analystes pour les conditions à ajouter
  ];

  private erreursDateOrdonnance = [
    { condition: (ordonnance: ITraitement) => !ordonnance.beginDate, message: "Date de l'ordonnance non renseignée", etat: EControleEtat.ERROR },
    //TODO: à voir avec les analystes pour les conditions à ajouter
  ];
  private erreursScanOrdonnance = [
    { condition: (ordonnance: ITraitement) => ordonnance.documents?.length === 0, message: "Aucun scan de l'ordonnance", etat: EControleEtat.WARNING },
    //TODO: à voir avec les analystes pour les conditions à ajouter
  ];

  private erreursAccordPrealable = [
    { condition: (ordonnance: ITraitement) => this.accordPrealable?.resultat !== true, message: "Demande d'accord préalable non validée", etat: EControleEtat.ERROR },
  ];

  private erreursPrescripteur = [
    { condition: (prescripteur: IContact) => !prescripteur.finess, message: "N° du prescripteur non renseigné", etat: EControleEtat.ERROR },
  ];

  private erreursAmo = [
    { condition: (couvAmo: AMOP) => !couvAmo, message: "Régime obligatoire non renseigné", etat: EControleEtat.ERROR },
  ];

  private erreursAmoNir = [
    { condition: (couvAmo: AMOP) => !couvAmo || !couvAmo.nir, message: "Numéro de sécurité social non renseigné", etat: EControleEtat.ERROR },
    //TODO : il faut mettre l'algo qui vérifie si le NIR est correct
    { condition: (couvAmo: AMOP) => couvAmo.nir?.length < 13, message: "Numéro de sécurité social incorrect", etat: EControleEtat.ERROR },
  ];

  private erreursAmoCouv = [
    // { condition: (couvAmo: AMOP) => !couvAmo || (!couvAmo.dateDebut && !couvAmo.dateFin), message: "Dates de couverture non renseignées", etat: EControleEtat.ERROR },
    // { condition: (couvAmo: AMOP) => !couvAmo.dateDebut, message: "Date de début non renseignée", etat: EControleEtat.WARNING },
    // { condition: (couvAmo: AMOP) => !couvAmo.dateFin, message: "Date de fin non renseignée", etat: EControleEtat.WARNING },
    { condition: (couvAmo: AMOP) => !couvAmo.isActiveAnakin, message: "Les couvertures ne sont plus valides", etat: EControleEtat.ERROR },
  ];

  private erreursAmoMode = [
    { condition: (couvAmo: AMOP) => !couvAmo || !couvAmo.updateMode || couvAmo.updateMode === EUpdateMode.manual, message: "Aucune lecture carte vitale ou ADRi", etat: EControleEtat.WARNING },
  ];

  private erreursAmc = [
    { condition: (couvAmc: AMCP) => !couvAmc, message: "Mutuelle non renseignée", etat: EControleEtat.ERROR },
    { condition: (couvAmc: AMCP) => (couvAmc.gestionUnique && !couvAmc.mutnum) || (!couvAmc.gestionUnique && !couvAmc.amcId), message: "N° de télétransmission non renseigné", etat: EControleEtat.ERROR },
  ];

  private erreursAmcCouv = [
    { condition: (couvAmc: AMCP) => !couvAmc || (!couvAmc.dateDebut && !couvAmc.dateFin), message: "Dates de couverture non renseignées", etat: EControleEtat.ERROR },
    { condition: (couvAmc: AMCP) => !couvAmc.isActive, message: "Date de fin dépassée", etat: EControleEtat.ERROR },
    { condition: (couvAmc: AMCP) => !couvAmc.dateFin, message: "Date de fin non renseignée", etat: EControleEtat.WARNING },
    { condition: (couvAmc: AMCP) => !couvAmc.idConvention, message: "Convention non sélectionnée", etat: EControleEtat.ERROR },
  ];

  private erreursAmcConvention = [
    { condition: (couvAmc: AMCP) => !couvAmc.idConvention, message: "Convention non sélectionnée", etat: EControleEtat.ERROR },
  ];

  private erreursInfirmier = [
    // { condition: (infirmier: IContact) => !infirmier.codeCpsSaved, message: "Carte CPS non enregistrée", etat: EControleEtat.ERROR },
  ];

  private erreursSoinsAFacturer = [
    { condition: (seances: StoredSeance[]) => !ArrayHelper.hasElements(seances), message: "Aucune séance à facturer", etat: EControleEtat.ERROR },
    { condition: (seances: StoredSeance[]) => seances.some(x => x.status === EStatusSeance.to_be_done || x.status === EStatusSeance.canceled), message: "Aucune séance réalisée", etat: EControleEtat.WARNING },
    { condition: (seances: StoredSeance[]) => !seances.some(x => x.status === EStatusSeance.done || x.status === EStatusSeance.to_be_done || x.status === EStatusSeance.canceled), message: "Aucune séance à facturer", etat: EControleEtat.ERROR },
    //TODO : A voir si on garde ici le cas ou il y a des séances annulées ou non réalisées
    // { condition: (seances: StoredSeance[]) => seances.some(x => x.status === EStatusSeance.canceled || x.status === EStatusSeance.to_be_done), message: "Il y a des séances non réalisées ou annulées", etat: EControleEtat.WARNING },
  ];



  private accordPrealable: IAccordPrealable;

  private checkAccordPrealable: boolean = false;

  constructor(
    private svcDevice: DeviceService,
    private svcTraitement: TraitementService,
    private svcPatient: PatientsService,
    private router: Router,
    private route: ActivatedRoute,
    private svcSeance: SeanceService,
    private svcSeanceGenerator: SeancesGeneratorService,
    private svcContact: ContactsService,
    private readonly svcCouvertures: CouverturesService,
    private svcPanneau: PanneauService,
    private svcDrawerPopover: DrawerPopoverService,
    private svcInterventionStatement: InterventionStatementService,
    private svcSnackbar: SnackbarService,
    private svcAccordPrealable: AccordPrealableService
  ) {
    super();
  }



  ngOnInit() {
    this.getDateFacturationFromRoute();
    this.idOrdonnance = this.route.snapshot.paramMap.get('id')!;
    if (this.idOrdonnance) {
      this.getOrdonnance();
    }

    this.svcDevice.isMobile$
      .pipe(takeUntil(this.destroyed$))
      .subscribe((flag: boolean) => {
        this.isMobile = flag;
      });

    this.svcPanneau.closePanel$
      .pipe(
        takeUntil(this.destroyed$),
        switchMap(() => {
          this.refreshControleFacturation();
          return of(true);
        })
      )
      .subscribe();
  }

  public refreshControleFacturation() {
    this.controlesFacturation.initControle();
    this.getOrdonnance();
    this.checkIsFacturable();

  }

  // Modifie le tiers payant de l'AMO active tu patient
  toggleTiersPayantAMO = (value: boolean) => {
    if (this.patient && this.patient.AMO && this.patient.AMO.length > 0 && this.patient.AMO[0]) {
      this.patient.AMO[0].tp = value;
    }
  }

  // Modifie le tiers payant de l'AMC active tu patient
  toggleTiersPayantAMC = (value: boolean) => {
    if (this.patient && this.patient.AMC && this.patient.AMC.length > 0 && this.patient.AMC[0]) {
      this.patient.AMC[0].tp = value;
    }
  }

  onClickCancel() {
    this.router.navigate(["facturation"]);
  }

  facturer() {
    this.openPanneauFacturation();
  }

  private getDateFacturationFromRoute() {
    const state = this.router.getCurrentNavigation()?.extras.state as { dateFacturation: Date; };
    if (state?.dateFacturation) {
      // this.dateFacturation = new Date(state.dateFacturation);
      this.dateFacturation = state.dateFacturation;
    } else {
      this.dateFacturation = history.state.dateFacturation || new Date();
    }
  }

  private openPanneauFacturation() {
    this.facturationStart = true;
    if (this.isMobile) {
      this.svcPanneau.close();
      this.svcDrawerPopover.open("Facturation Sesam-Vitale",
        "700px",
        null,
        FacturationSvComponent,
        {
          seancesAFacturer: this.seancesAFacturer,
          seances: this.seances,
          ordonnance: this.ordonnance,
          patient: this.patient,
        })
    } else {
      this.svcPanneau.open("Facturation Sesam-Vitale", FacturationSvComponent, {
        seancesAFacturer: this.seancesAFacturer,
        seances: this.seances,
        ordonnance: this.ordonnance,
        patient: this.patient,
      });
    }
  }

  private getOrdonnance() {
    this.facturationStart = false;
    //TODO : à changer en getITraitementANAKIN si on ne récupère pas les documents dans le type Traitement
    this.svcTraitement.getTraitementANAKIN(this.idOrdonnance).pipe(
      tap((ordo: ITraitement) => {
        this.ordonnance = ordo;
        const patientId = Traitement.extractPatientId(this.idOrdonnance);
        this.getPatient(patientId);
        this.checkAccordPrealable = this.ordonnance.actes.some(acte => acte.isPriorAgreement)
      }),
      switchMap(() => {
        if (!this.checkAccordPrealable)
          return of(null)

        return this.svcAccordPrealable.get(this.ordonnance._id).pipe(
          tap((accord: IAccordPrealable) => {
            this.accordPrealable = accord;
          }),
          catchError(() => {
            return of(null)
          })
        )
      }),
      tap(() => { this.getOrdonnanceControles(this.ordonnance) }),
      mergeMap(() => this.svcSeance.selectSeancesByTraitement(this.ordonnance)
        .pipe(
          tap((seances) => {
            this.seances = seances;
            const dateFacturationFinJournee = DateHelper.fillDay(this.dateFacturation ?? new Date());
            const seanceRangeDate = seances.filter((seance: StoredSeance) => {
              return DateHelper.isBetweenTwoDates(seance.startDate, DateHelper.resetDay(this.ordonnance.beginDate), dateFacturationFinJournee);
            });
            //On veut juste les séances que l'on doit facturer sur la plage défini dans la page de facturation        
            this.seancesAFacturer = seanceRangeDate;
            this.seancesFacturables = this.seancesAFacturer;
            this.getSoinsAFacturerControles(seanceRangeDate);
          }),
          switchMap(() => this.svcContact.getSiteContactsAnakin([], EPrefix.contact, true, true)),
          map((contacts: IContact[]) => {
            return contacts.map((contact: IContact) => {
              if (!contact.isDoctor && contact.finess)
                contact.isDoctor = true;
              return contact;
            });
          }),
          tap((contacts: IContact[]) => {
            const infirmiers = contacts.filter(contact => contact.userId);
            this.getInfirmiersControles(infirmiers);
          })
        )
      ),
      takeUntil(this.destroyed$)
    ).subscribe(() => {
      this.checkIsFacturable();
    });
  }

  private getPatientControles(patient: IPatient) {
    let controlePatient: IControleBase = {} as IControleBase;

    // Trouve la première erreur applicable
    const premiereErreur = this.erreursPatient.find(erreur => erreur.condition(patient));

    // Défini l'état : s'il y a une erreur, met l'état à ERROR, sinon à OK
    controlePatient.etat = premiereErreur ? EControleEtat.ERROR : EControleEtat.OK;
    controlePatient.libellePrincipal = [patient.lastName?.toUpperCase(), patient.firstName].filter(Boolean).join(' ');
    controlePatient.isBold = true;
    controlePatient.libelleSecondaire = [patient.street, patient.city].filter(Boolean).join(', ');

    // Défini le libellé d'erreur basé sur la première erreur trouvée
    controlePatient.libelleErreur = premiereErreur ? premiereErreur.message : "";
    controlePatient.onClick = this.openPanneauIdentitePatient;

    this.controlesFacturation.patient = controlePatient;

  }

  private openPanneauSecuriteSociale = () => {
    const panneauTitle = "Assurance maladie obligatoire";
    const panneauContent = PanneauPatientAmoComponent;
    const panneauInputs = {
      patient: this.patient,
      ...(this.patient.AMO[0] && { couverture: this.patient.AMO[0] })
    };
    this.svcPanneau.open(
      panneauTitle,
      panneauContent,
      panneauInputs
    );
  }

  public openPanneauMutuelle = () => {
    const panneauTitle = "Mutuelle";
    const panneauContent = PanneauPatientAmcComponent;
    const panneauInputs = {
      patient: this.patient,
      ...(this.patient.AMC[0] && { couverture: this.patient.AMC[0] })
    };
    this.svcPanneau.open(
      panneauTitle,
      panneauContent,
      panneauInputs
    );
  }

  private openPanneauIdentitePatient = () => {
    const panneauTitle = "Identification du patient";
    const panneauContent = PanneauIdentitePatientComponent;
    const panneauInputs = {
      patient: this.patient
    };
    this.svcPanneau.open(
      panneauTitle,
      panneauContent,
      panneauInputs
    );
  }

  private ajouterControleOrdonnance(
    erreurs: { condition: (ordonnance: ITraitement) => boolean, etat: EControleEtat, message: string }[],
    ordonnance: ITraitement,
    libelle: string,
    onClick?: () => void
  ) {
    let controle: IControleBase = {} as IControleBase;

    // Trouve la première erreur applicable
    const premiereErreur = erreurs.find(erreur => erreur.condition(ordonnance));

    // Défini l'état : s'il y a une erreur, met l'état à ERROR, sinon à OK
    controle.etat = premiereErreur?.etat ?? EControleEtat.OK;

    // Défini le libellé principal en fonction de l'état
    controle.libellePrincipal = controle.etat !== EControleEtat.ERROR ? libelle : "";

    // Défini le libellé d'erreur basé sur la première erreur trouvée
    controle.libelleErreur = premiereErreur ? premiereErreur.message : "";
    controle.onClick = onClick ?? this.openPanneauOrdonnance;

    // Ajoute le contrôle à la facturation
    this.controlesFacturation.ordonnance.push(controle);
  }


  private getOrdonnanceControles(ordonnance: ITraitement) {
    //Prescripteur
    this.svcContact.getContact(ordonnance.prescripteurContactId).pipe(
      tap((prescripteur) => {
        this.getOrdonnancePrescripteurControles(prescripteur)

        // Contrôle pour la date de l'ordonnance
        const libellePrincipalDate = ["Du", DateHelper.transform(ordonnance.beginDate, ETimetablePattern.dd_MM_yyyy_slash)].filter(Boolean).join(' ');
        this.ajouterControleOrdonnance(this.erreursDateOrdonnance, ordonnance, libellePrincipalDate);

        // Contrôle pour le scan de l'ordonnance
        this.ajouterControleOrdonnance(this.erreursScanOrdonnance, ordonnance, "Scan de l'ordonnance (SCOR)");

        // Contrôle pour l'accord préalable de l'ordonnance
        if (this.checkAccordPrealable) {
          this.ajouterControleOrdonnance(this.erreursAccordPrealable, ordonnance, "Accord préalable", this.openPanneauAccordPrealable);
        }
      }),
      takeUntil(this.destroyed$)
    ).subscribe();
  }

  private getOrdonnancePrescripteurControles(prescripteur: IContact) {
    let controlePresc: IControleBase = {} as IControleBase;
    const premiereErreur = this.erreursPrescripteur.find(erreur => erreur.condition(prescripteur));
    // Défini l'état : s'il y a une erreur, met l'état à ERROR, sinon à OK
    controlePresc.etat = premiereErreur?.etat ?? EControleEtat.OK;
    controlePresc.libellePrincipal = [prescripteur.isDoctor ? "Dr" : "", prescripteur.lastName?.toUpperCase(), prescripteur.firstName].filter(Boolean).join(' ');
    controlePresc.isBold = true;
    controlePresc.libelleSecondaire = prescripteur.rpps ? `RPPS : ${prescripteur.rpps}` : ''
    // Défini le libellé d'erreur basé sur la première erreur trouvée
    controlePresc.libelleErreur = premiereErreur ? premiereErreur.message : "";
    controlePresc.onClick = this.openPanneauOrdonnance;

    this.controlesFacturation.ordonnance.push(controlePresc);
  }

  private openPanneauOrdonnance = () => {
    const panneauTitle = "Ordonnance";
    const panneauContent = PanneauOrdonnanceComponent;
    const panneauInputs = {
      ordonnance: this.ordonnance
    };
    this.svcPanneau.open(
      panneauTitle,
      panneauContent,
      panneauInputs
    );
  }

  private openPanneauAccordPrealable = () => {
    const panneauTitle = "Accord préalable";
    const panneauContent = PanneauAccordPrealableComponent;
    const panneauInputs = {
      ordonnance: this.ordonnance,
      accord: this.accordPrealable
    };
    this.svcPanneau.open(
      panneauTitle,
      panneauContent,
      panneauInputs
    );
  }

  private getAmoControles(patient: IPatient) {
    const amoP: AMOP = ArrayHelper.hasElements(patient.AMO) ? patient.AMO.find(x => x.isActiveAnakin) ?? patient.AMO[0] : null;

    //Si on a pas d'amo on grise tous les controles
    let controleTP: IControleBase = {} as IControleBase;
    let controleAmo: IControleBase = {} as IControleBase;
    let controleAmoNir: IControleBase = {} as IControleBase;
    let controleAmoCouv: IControleBase = {} as IControleBase;
    let controleAmoMode: IControleBase = {} as IControleBase;

    if (!amoP) {
      controleAmo.etat = EControleEtat.DISABLED;
      controleAmo.libelleErreur = "Régime obligatoire";

      controleAmoNir.etat = EControleEtat.DISABLED;
      controleAmoNir.libelleErreur = "Numéro de sécurité sociale";

      controleAmoCouv.etat = EControleEtat.DISABLED;
      controleAmoCouv.libelleErreur = "Période de droits";

      controleAmoMode.etat = EControleEtat.DISABLED;
      controleAmoMode.libelleErreur = "Contrôle ADRI ou carte vitale";

      controleTP.isDisabled = true;
    } else {
      const premiereErreurAmo = this.erreursAmo.find(erreur => erreur.condition(amoP));
      const libelleAmo = amoP ? this.svcCouvertures.getLabelCouverture(amoP.amoId, this.etablissementsById) : "";
      controleAmo.etat = premiereErreurAmo?.etat ?? EControleEtat.OK;
      controleAmo.libellePrincipal = controleAmo.etat !== EControleEtat.ERROR ? libelleAmo : "";
      controleAmo.isBold = true;
      controleAmo.libelleSecondaire = controleAmo.etat !== EControleEtat.ERROR ? amoP.amoId.replace("amo_", "").replace(/-/g, "") : "";
      controleAmo.libelleErreur = premiereErreurAmo ? premiereErreurAmo.message : "";

      const premiereErreurAmoNir = this.erreursAmoNir.find(erreur => erreur.condition(amoP));
      const libelleAmoNir = amoP ? amoP.nir : "";
      controleAmoNir.etat = premiereErreurAmoNir?.etat ?? EControleEtat.OK;
      controleAmoNir.libellePrincipal = libelleAmoNir;
      controleAmoNir.libelleErreur = premiereErreurAmoNir ? premiereErreurAmoNir.message : "";

      const premiereErreurAmoCouv = this.erreursAmoCouv.find(erreur => erreur.condition(amoP));
      const libelleAmoCouv = amoP ? [amoP.dateDebut ? "Du" : "", DateHelper.transform(amoP.dateDebut, ETimetablePattern.dd_MM_yyyy_slash), amoP.dateDebut ? (amoP.dateFin ? "au" : "") : (amoP.dateFin ? "jusqu'au" : ""), DateHelper.transform(amoP.dateFin, ETimetablePattern.dd_MM_yyyy_slash)].filter(Boolean).join(' ') : "";
      if (!amoP.dateDebut && !amoP.dateFin) {
        controleAmoCouv.etat = EControleEtat.DISABLED
        controleAmoCouv.libelleErreur = "Période de droits";
      } else {
        controleAmoCouv.libellePrincipal = libelleAmoCouv;
        controleAmoCouv.etat = premiereErreurAmoCouv?.etat ?? EControleEtat.OK;
        controleAmoCouv.libelleErreur = premiereErreurAmoCouv ? premiereErreurAmoCouv.message : "";
      }

      const premiereErreurAmoMode = this.erreursAmoMode.find(erreur => erreur.condition(amoP));
      const libelleAmoMode = "Contrôle ADRI ou carte vitale";
      controleAmoMode.etat = premiereErreurAmoMode?.etat ?? EControleEtat.OK;
      controleAmoMode.libellePrincipal = controleAmo.etat !== EControleEtat.ERROR ? libelleAmoMode : "";
      controleAmoMode.libelleErreur = premiereErreurAmoMode ? premiereErreurAmoMode.message : "";

      controleTP.onToggleSwitch = this.toggleTiersPayantAMO
    }

    controleTP.isSwitchOnly = true;
    controleTP.isChecked = amoP?.tp || false;
    controleTP.libellePrincipal = "Tiers payant";
    this.controlesFacturation.amo.push(controleTP);

    controleAmo.onClick = this.openPanneauSecuriteSociale;
    controleAmoNir.onClick = this.openPanneauSecuriteSociale;
    controleAmoCouv.onClick = this.openPanneauSecuriteSociale;
    controleAmoMode.onClick = this.openPanneauSecuriteSociale;
    this.controlesFacturation.amo.push(controleAmo);
    this.controlesFacturation.amo.push(controleAmoNir);
    this.controlesFacturation.amo.push(controleAmoCouv);
    this.controlesFacturation.amo.push(controleAmoMode);

  }

  private getAmcControles(patient: IPatient) {
    const amcP: AMCP = ArrayHelper.hasElements(patient.AMC) ? patient.AMC.find(x => x.isActive) ?? patient.AMC[0] : null;

    let controleTP: IControleBase = {} as IControleBase;
    let controleAmc: IControleBase = {} as IControleBase;
    let controleAmcCouv: IControleBase = {} as IControleBase;
    let controleAmcMode: IControleBase = {} as IControleBase;
    if (!amcP) {
      controleAmc.etat = EControleEtat.DISABLED;
      controleAmc.libelleErreur = "Tiers payeur mutuelle";

      controleAmcCouv.etat = EControleEtat.DISABLED;
      controleAmcCouv.libelleErreur = "Période  de droits";

      controleAmcMode.etat = EControleEtat.DISABLED;
      controleAmcMode.libelleErreur = "Convention de télétransmission";

      controleTP.isDisabled = true;
    } else {
      const premiereErreurAmc = this.erreursAmc.find(erreur => erreur.condition(amcP));
      const libelleAmc = amcP ? this.svcCouvertures.getLabelCouverture(amcP.amcId, this.etablissementsById) : "";
      controleAmc.etat = premiereErreurAmc?.etat ?? EControleEtat.OK;
      controleAmc.libellePrincipal = controleAmc.etat !== EControleEtat.ERROR ? libelleAmc : "";
      controleAmc.isBold = true;
      controleAmc.libelleSecondaire = controleAmc.etat !== EControleEtat.ERROR ? amcP.gestionUnique ? amcP.mutnum : amcP.amcId : "";
      controleAmc.libelleErreur = premiereErreurAmc ? premiereErreurAmc.message : "";

      const premiereErreurAmcCouv = this.erreursAmcCouv.find(erreur => erreur.condition(amcP));
      const libelleAmcCouv = amcP ? [amcP.dateDebut ? "Du" : "", DateHelper.transform(amcP.dateDebut, ETimetablePattern.dd_MM_yyyy_slash), amcP.dateDebut ? (amcP.dateFin ? "au" : "") : (amcP.dateFin ? "jusqu'au" : ""), DateHelper.transform(amcP.dateFin, ETimetablePattern.dd_MM_yyyy_slash)].filter(Boolean).join(' ') : "";
      controleAmcCouv.etat = premiereErreurAmcCouv?.etat ?? EControleEtat.OK;
      controleAmcCouv.libellePrincipal = libelleAmcCouv;
      controleAmcCouv.libelleErreur = premiereErreurAmcCouv ? premiereErreurAmcCouv.message : "";

      //TODO : Faire un appel à Intellio editeur pour récupérer la convention
      const premiereErreurAmcConv = this.erreursAmcConvention.find(erreur => erreur.condition(amcP));
      const libelleAmcMode = !amcP.convention ? "Convention sélectionnée" : [amcP.convention.libelle, amcP.convention.estTp ? "(TP)" : ""].filter(Boolean).join(" ");
      const libelleSecAmcMode = !amcP.convention ? "" : [amcP.convention.typeConvention, amcP.convention.codeOganismeComplementaire, amcP.convention.critereSecondaire].filter(Boolean).join(" - ");
      controleAmcMode.etat = premiereErreurAmcConv?.etat ?? EControleEtat.OK;
      controleAmcMode.libellePrincipal = controleAmc.etat !== EControleEtat.ERROR ? libelleAmcMode : "";
      controleAmcMode.isBold = true;
      controleAmcMode.libelleSecondaire = controleAmc.etat !== EControleEtat.ERROR ? libelleSecAmcMode : "";
      controleAmcMode.libelleErreur = premiereErreurAmcConv ? premiereErreurAmcConv.message : "";

      controleTP.onToggleSwitch = this.toggleTiersPayantAMC
    }
    controleTP.isSwitchOnly = true;
    controleTP.isChecked = amcP?.tp || false;
    controleTP.libellePrincipal = "Tiers payant";

    controleAmc.onClick = this.openPanneauMutuelle
    controleAmcCouv.onClick = this.openPanneauMutuelle
    controleAmcMode.onClick = this.openPanneauMutuelle
    this.controlesFacturation.amc.push(controleTP);
    this.controlesFacturation.amc.push(controleAmc);
    this.controlesFacturation.amc.push(controleAmcCouv);
    this.controlesFacturation.amc.push(controleAmcMode);
  }

  private getSoinsAFacturerControles(seances: StoredSeance[]) {
    this.controlesFacturation.soinsAFacturer = [];
    let controleDateSeance: IControleBase = {} as IControleBase;
    controleDateSeance.etat = EControleEtat.OK;
    controleDateSeance.iconEnd = "";
    controleDateSeance.libellePrincipal = ["jusqu'au", DateHelper.transform(this.dateFacturation, ETimetablePattern.dd_MM_yyyy_slash)].join(" ");
    this.controlesFacturation.soinsAFacturer.push(controleDateSeance);

    let controleSeances: IControleBase = {} as IControleBase;
    const nbSeancesAFacturer = seances.filter(x => x.status === EStatusSeance.done).length;
    const nbSeancesAnnuleesOuNonRealisees = seances.filter(x => x.status === EStatusSeance.canceled || x.status === EStatusSeance.to_be_done).length;
    const aucuneSeances = nbSeancesAFacturer === 0 && nbSeancesAFacturer === 0;
    const premiereErreurSeances = this.erreursSoinsAFacturer.find(erreur => erreur.condition(seances));
    const libelleSeance = [nbSeancesAFacturer, nbSeancesAFacturer > 1 ? "séances" : "séance", "à facturer"].join(" ");
    const libelleSeanceAnnulee = [nbSeancesAnnuleesOuNonRealisees, nbSeancesAnnuleesOuNonRealisees > 1 ? "séances non réalisées ou annulées" : "séance non réalisée ou annulée"].join(" ");
    controleSeances.iconEnd = "chevron_right";
    controleSeances.etat = premiereErreurSeances?.etat ?? (nbSeancesAnnuleesOuNonRealisees ? EControleEtat.WARNING : EControleEtat.OK);
    controleSeances.libellePrincipal = controleSeances.etat !== EControleEtat.ERROR ? libelleSeance : "";
    controleSeances.isBold = true;
    controleSeances.libelleSecondaire = controleSeances.etat !== EControleEtat.ERROR ? "voir le détail" : "";
    controleSeances.libelleErreur = premiereErreurSeances && controleSeances.etat === EControleEtat.WARNING ? libelleSeanceAnnulee : (premiereErreurSeances && controleSeances.etat === EControleEtat.ERROR ? premiereErreurSeances.message : "");
    controleSeances.onClick = this.openPanneauSoinsAFacturer;
    this.controlesFacturation.soinsAFacturer.push(controleSeances);
  }

  public openPanneauSoinsAFacturer = () => {
    if (this.isMobile) {
      const drawerPanneauTitle = "Séances à facturer";
      const drawerPanneauContent = GroupeListeSeancesComponent;
      const drawerPanneauInputs = {
        seances: this.seancesAFacturer,
        ordonnance: this.ordonnance,
        patient: this.patient,
      };
      this.svcPanneau.open(
        drawerPanneauTitle,
        drawerPanneauContent,
        drawerPanneauInputs
      );
    }
    else {
      this.scrollContainer.nativeElement.scrollTo({ top: 0, behavior: 'smooth' });
    }
  }

  // Ajoute ou retire les séances de l'infirmier ciblé de la liste des séances à facturer
  toggleInfirmier = (value: boolean, id: string) => {
    if (!value) {
      this.seancesAFacturer = this.seancesAFacturer.filter((seance: StoredSeance) => seance.infirmierId !== id);
    } else {
      this.seancesAFacturer = [...this.seancesAFacturer, ...this.seancesFacturables.filter((seance: StoredSeance) => seance.infirmierId === id)];
    }
    this.getSoinsAFacturerControles(this.seancesAFacturer);
  }

  private getInfirmiersControles(infirmiers: IContact[]) {
    const infirmierIdsChecked = ArrayHelper.unique(this.seancesAFacturer.map(seance => seance.infirmierId))
    if (ArrayHelper.hasElements(infirmiers)) {
      let controlesInf: IControleBase[] = [];
      infirmiers.forEach((inf) => {
        let controleInf: IControleBase = {} as IControleBase;
        controleInf.libellePrincipal = [inf.firstName, inf.lastName?.toUpperCase()].filter(Boolean).join(" ");
        controleInf.isSwitch = true;
        if (infirmierIdsChecked.includes(inf._id)) {
          //TODO a décommenter lorsque la fonctionnalité de cps sur l'infirmier sera dev
          const premiereErreurInf = this.erreursInfirmier.find(erreur => erreur.condition(inf));
          controleInf.etat = premiereErreurInf?.etat ?? EControleEtat.OK; controleInf.isChecked = true;
          controleInf.onToggleSwitch = (isChecked: boolean) => this.toggleInfirmier(isChecked, inf._id);
        } else {
          controleInf.etat = EControleEtat.DISABLED;
          controleInf.isDisabled = true;
          controleInf.isChecked = false;
          controleInf.libelleSecondaire = 'Aucune séance'
        }
        controlesInf.push(controleInf);

      });
      this.controlesFacturation.infirmiers = controlesInf;
    }
  }



  private getPatient(patientId: string) {
    this.svcPatient.getPatient(patientId).pipe(
      tap((patient: IPatient) => {
        this.patient = patient;
        this.getPatientControles(patient);
      }),
      switchMap(() => this.svcCouvertures.getPatientSortedCouvertures(this.patient._id, false)),
      switchMap((poCouvertures: { AMOPs: AMOP[]; AMCPs: AMCP[] }) => {

        // AMO active du patient
        const activeAmo: AMOP = ArrayHelper.getFirstElement(poCouvertures.AMOPs.filter((amo: AMOP) => amo.isActiveAnakin));
        this.patient.AMO = activeAmo ? [activeAmo] : [];

        // AMC active du patient
        const activeAmc: AMCP = ArrayHelper.getFirstElement(poCouvertures.AMCPs.filter((amc: AMCP) => amc.isActiveAnakin));
        this.patient.AMC = activeAmc ? [activeAmc] : [];

        const idsCouv: string[] = []
        if (activeAmo) {
          idsCouv.push(activeAmo.amoId)
          activeAmo.nir = this.patient.socialNumber ?? undefined
        }
        if (activeAmc)
          idsCouv.push(activeAmc.amcId)

        //Récupération des établissements liés aux AMO et AMC du patient
        return this.svcCouvertures.getEtablissementsByIds(idsCouv)
      }),
      tap((etablissements: (IAMC | IAMO)[]) => {
        etablissements.forEach((etablissement: IAMC | IAMO) => this.etablissementsById.set(etablissement._id, etablissement));
      }),
      tap(() => {
        this.getAmoControles(this.patient);
        this.getAmcControles(this.patient);
      }),
      takeUntil(this.destroyed$),
      catchError(error => {
        console.error('Erreur lors de la récupération du patient et de ses couvertures ', error);
        return of(null);
      })
    ).subscribe();
  }

  private checkIsFacturable() {
    //TODO : ajouter des exceptions en fonction des retours, attente NIE
    this.isFacturable = this.seancesAFacturer.some(x => x.status === EStatusSeance.done)
      && !(this.controlesFacturation.patient?.etat === EControleEtat.ERROR
        || this.controlesFacturation.ordonnance.some(x => x.etat === EControleEtat.ERROR)
        || this.controlesFacturation.amo.some(x => x.etat === EControleEtat.ERROR)
        || this.controlesFacturation.amc.some(x => x.etat === EControleEtat.ERROR)
        || this.controlesFacturation.infirmiers.some(x => x.etat === EControleEtat.ERROR)
        || this.controlesFacturation.soinsAFacturer.some(x => x.etat === EControleEtat.ERROR))
  }

}

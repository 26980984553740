import { Component, Input, OnInit } from "@angular/core";
import { ArrayHelper, DateHelper, IdHelper } from "@osapp/helpers";
import { EPrefix } from "@osapp/model";
import { DestroyableComponentBase } from "@osapp/modules/utils/components/destroyable-component-base";
import { StoredSeance } from "apps/idl/src/anakin/models/StoredSeance";
import { ITransmissionRapport } from "apps/idl/src/modules/patients/model/ITransmissionRapport";
import { PatientsService } from "apps/idl/src/modules/patients/services/patients.service";
import { RapportService } from "apps/idl/src/modules/patients/services/rapport.service";
import { Observable, forkJoin, of } from "rxjs";
import { delay, switchMap, takeUntil, tap } from "rxjs/operators";
import { IPatient } from "../../../../../modules/patients/model/IPatient";
import { DeviceService } from "../../../shared/services/device.service";
import { LoaderService } from "../../../shared/services/loader.service";
import { PanneauService } from "../../../shared/services/panneau.service";
import { SeanceService } from "../../../shared/services/seance.service";
@Component({
	selector: "di-fil-de-soin",
	templateUrl: "./fil-de-soin.component.html",
	styleUrls: ["./fil-de-soin.component.scss"],
})
export class ListeFilDeSoinComponent
	extends DestroyableComponentBase
	implements OnInit
{
	@Input() public patient?: IPatient;

	public noMoreSeances: boolean = false;

	public seances: StoredSeance[] = [];
	public upcomingSeances: StoredSeance[] = [];
	public showUpcoming: boolean = false;
	public groupedData: {
		date: string;
		seances: StoredSeance[];
		transmissions: ITransmissionRapport[];
		sortDate?: Date;
	}[] = [];
	public visibleGroupedSeances: {
		date: string;
		seances: StoredSeance[];
		transmissions: ITransmissionRapport[];
	}[] = [];
	public isMobileView: boolean = false;

	constructor(
		private isvcSeance: SeanceService,
		private svcTransmission: RapportService,
		private svcDevice: DeviceService,
		private svcPatient: PatientsService,
		private svcLoader: LoaderService,
		private svcPanneau: PanneauService
	) {
		super();
	}

	ngOnInit() {
		this.svcDevice.isMobile$
			.pipe(takeUntil(this.destroyed$))
			.subscribe((flag: boolean) => {
				this.isMobileView = flag;
			});

		this.svcPanneau.closePanel$
		.pipe(
			takeUntil(this.destroyed$),
			switchMap(() => {
				this.loadListData();
				return of(true);
			})
		)
		.subscribe();

		this.loadListData();
	}

	private loadListData() {
		this.loadListSeances();
		this.loadListTransmission();
	}

	private loadListSeances() {
		this.initValueComposant();

		this.isvcSeance
			.selectSeancesByPatient(this.patient._id)
			.pipe(
				tap(() => this.svcLoader.showLoader("Chargement des seances ...")),
				tap((seances: StoredSeance[]) => {
					const today = new Date();

					this.seances = seances.filter(
						(seance) => new Date(seance.startDate) <= today
					);
					this.upcomingSeances = seances.filter(
						(seance) => new Date(seance.startDate) > today
					);

					this.manageSeances();
				}),
				delay(200),
				tap(() => {
					this.svcLoader.hideLoader();
				  }),
				takeUntil(this.destroyed$)
			)
			.subscribe();
	}
	private loadUpcommingSeances() {
		this.showUpcoming = true;
		const today = new Date();

		this.seances = [...this.seances, ...this.upcomingSeances];

		this.groupDataByDate();
	}
	private initValueComposant() {
		this.seances = [];
		this.groupedData = [];
		this.visibleGroupedSeances = [];
		this.showUpcoming = false;
	}

	private manageSeances() {
		if (!ArrayHelper.hasElements(this.seances)) return;

		this.groupDataByDate();
	}
	private groupDataByDate(): void {
		const dataByDate: {
			[key: string]: {
				seances?: StoredSeance[];
				transmissions?: ITransmissionRapport[];
				sortDate?: Date;
			};
		} = {};
		const today = new Date();

		this.seances.sort(
			(a, b) =>
				new Date(b.startDate).getTime() - new Date(a.startDate).getTime()
		);

		this.seances.forEach((seance) => {
			const dateKey = this.getFormattedDate(new Date(seance.startDate), today);
			if (!dataByDate[dateKey]) {
				dataByDate[dateKey] = { sortDate: new Date(seance.startDate) };
			}
			if (!dataByDate[dateKey].seances) {
				dataByDate[dateKey].seances = [];
			}
			dataByDate[dateKey].seances.push(seance);
		});

		this.transmissions.forEach((transmission) => {
			const dateKey = this.getFormattedDate(
				new Date(transmission.createdDate),
				today
			);
			if (!dataByDate[dateKey]) {
				dataByDate[dateKey] = { sortDate: new Date(transmission.createdDate) };
			}
			if (!dataByDate[dateKey].transmissions) {
				dataByDate[dateKey].transmissions = [];
			}
			dataByDate[dateKey].transmissions.push(transmission);
		});

		this.groupedData = Object.keys(dataByDate).map((date) => ({
			date: date,
			seances: dataByDate[date].seances || [],
			transmissions: dataByDate[date].transmissions || [],
			sortDate: dataByDate[date].sortDate,
		}));
		this.groupedData.sort(
			(a, b) => new Date(b.sortDate).getTime() - new Date(a.sortDate).getTime()
		);
	}

	public getFormattedDate(date: Date, today: Date): string {
		const dateOnly = new Date(
			date.getFullYear(),
			date.getMonth(),
			date.getDate()
		);
		const todayOnly = new Date(
			today.getFullYear(),
			today.getMonth(),
			today.getDate()
		);

		const isToday = DateHelper.compareTwoDates(dateOnly, todayOnly) === 0;

		return isToday
			? `Aujourd'hui, ${date.toLocaleDateString("fr-FR", {
					weekday: "long",
					year: "numeric",
					month: "long",
					day: "numeric",
			  })}`
			: date.toLocaleDateString("fr-FR", {
					weekday: "long",
					year: "numeric",
					month: "long",
					day: "numeric",
			  });
	}

	public showUpcomingSeances(): void {}

	public transmissions: ITransmissionRapport[] = [];
	private patientIds: string[];

	public patientsMap: Map<string, IPatient> = new Map();
	public getIdPatient(string: string): string {
		return `${EPrefix.patient}${
			IdHelper.getGuidFromId(string, EPrefix.patient).split(
				IdHelper.C_ID_SEPARATOR
			)[0]
		}`;
	}

	private loadListTransmission(): void {
		this.initTransmissions()
			.pipe(
				tap(() => this.svcLoader.showLoader("Chargement des transmissions ...")),
				switchMap(() => {
					this.patientIds = Array.from(
						new Set(
							this.transmissions
								.map((trans) => this.getIdPatient(trans._id))
								.filter((id) => id)
						)
					);
					return forkJoin({
						patients: this.svcPatient.getPatientsByIds(this.patientIds),
					});
				}),
				tap(({ patients }) => {
					this.patientsMap = new Map(
						patients.map((patient) => [patient._id, patient])
					);

					this.transmissions = this.transmissions.map((transmission) => {
						const patientId = this.getIdPatient(transmission._id);

						return {
							...transmission,
							patientId: patientId || null,
						};
					});
				}),
				tap(() => {
					const patientTransmissions = this.transmissions.filter(
						(trans) => trans.patientId === this.patient._id
					);

					this.transmissions = patientTransmissions;
					this.groupDataByDate();
				}),
				delay(200),
				tap(() => {
					this.svcLoader.hideLoader();
				  }),
				takeUntil(this.destroyed$)
			)
			.subscribe();
	}

	private initTransmissions(): Observable<ITransmissionRapport[]> {
		return this.svcTransmission.getTransmissionsAnakin(false).pipe(
			tap((transmissions: ITransmissionRapport[]) => {
				this.transmissions = transmissions.map((transmission) => {
					return {
						...transmission,
						createdDate: new Date(transmission.createdDate),
					};
				});

				this.transmissions.sort((a, b) => {
					return (
						(b.createdDate as Date).getTime() -
						(a.createdDate as Date).getTime()
					);
				});
			})
		);
	}
	public getPatientFromId(id: string): IPatient {
		return this.patientsMap.get(this.getIdPatient(id));
	}
}

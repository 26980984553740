import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';
import { NavigationItems } from 'apps/idl/src/anakin/shared/navigation-items.constants';
import { NavigationService } from '../../services/navigation.service';
import { HistoriqueService } from '../../services/historique.service';
import { RapportService } from 'apps/idl/src/modules/patients/services/rapport.service';

@Component({
	selector: "di-sidemenu-desktop",
	templateUrl: './sidemenu-desktop.component.html',
	styleUrls: ['./sidemenu-desktop.component.scss'],
})
export class SidemenuDesktopComponent implements OnInit {

	public menuIcones = NavigationItems;

	constructor(private router: Router,
		private svcNavigation: NavigationService,
		private svcHistorique: HistoriqueService,
		private readonly svcTransmission: RapportService,

		private cdr: ChangeDetectorRef,
		private ioInAppBrowser: InAppBrowser
	) { }

	ngOnInit() {
		this.svcNavigation.navigationItems$.subscribe(items => {
			this.menuIcones = items;
			this.cdr.detectChanges();
		});
	}

	public redirect(route: string[]): void {
		if (!route.includes("#"))
			this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
				this.router.navigate(route)
			});
	}

	public handleRedirectAmeli() {
		const url = 'https://www.ameli.fr/meurthe-et-moselle/infirmier/exercice-liberal/prescription-prise-charge/regles-exercice-formalites/bilan-de-soins-infirmiers-bsi';
		this.ioInAppBrowser.create(url);
	}

}

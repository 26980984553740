<div class="di-fil-de-soin__container">
	<div>
		<div *ngIf="seances.length > 0 || transmissions.length > 0">
			<lua-bouton-texte *ngIf="!showUpcoming" libelle="Afficher les soins à venir" [fullWidth]="true" size="large"
				endIcon="arrow_upward" (click)="loadUpcommingSeances()"></lua-bouton-texte>
			<div *ngFor="let group of groupedData">
				<div class="seance-par-jour">
					<h4 class="titre">{{ group.date }}</h4>
					<div *ngFor="let seance of group.seances">
						<di-carte-seance [seance]="seance" [patient]="patient"></di-carte-seance>
					</div>
					<div *ngFor="let transmission of group.transmissions">
						<lua-papier class="list__item" [ngClass]="'filled'" mode="filled" [fullWidth]="true">
							<di-carte-transmission [isMobileView]="isMobileView" [transmission]="transmission" [isComment]="false"
								[isNew]="false">
							</di-carte-transmission>
							<ng-container *ngFor="let commentaire of transmission?.commentaires">
								<di-carte-transmission [transmission]="transmission" [commentaire]="commentaire" [isComment]="true"
									[isNew]="false">
								</di-carte-transmission>
							</ng-container>
						</lua-papier>
					</div>
				</div>
			</div>
		</div>
		<ng-container *ngIf="seances.length === 0 && transmissions.length === 0">
			<lua-message-aucune-donnee mainText="Aucun historique patient"
			subText="Vous retourverez ici les soins et transmissions du patient"></lua-message-aucune-donnee>
		</ng-container>
		<di-menu-fil-de-soins [patient]="patient"></di-menu-fil-de-soins>
	</div>
</div>